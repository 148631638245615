import React, { useState, useEffect, useRef, useCallback } from "react"
import icon from "./icons/check.svg"
import "./DropdownMulti.scss"

type propTypes = {
  updateOptions: Function
  options: any[]
  chosenValues: any[]
  dropdown?: boolean
  title: string
  prop: string
}

export default function DropdownMulti(props: propTypes) {
  const { updateOptions, options, title, prop } = props
  const [allLocalProps, setAllLocalProps] = useState(options)
  const [chosenValues, setChosenValues] = useState([] as any)
  const [showingValues, setShowingValues] = useState(options)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [searchfieldtext, setSearchfieldtext] = useState("")
  const maxItems = 100
  const dropdownRef = useRef<HTMLInputElement>(null)

  const handleKeyDownOnInput = (e: any) => {
    var input = e.target.value
    if (!e.target.value.replace(/\s/g, "").length || e.target.value === "") {
      setSearchfieldtext(input)
      setShowingValues(allLocalProps)
    }
    if (/[a-zA-Z0-9-_ ]/.test(input)) {
      const showingValuesList = [] as any
      for (var i = 0; i < allLocalProps.length; i++) {
        if (
          allLocalProps[i] !== null &&
          allLocalProps[i].toLowerCase().includes(input.toLowerCase())
        ) {
          showingValuesList[showingValuesList.length] = allLocalProps[i]
        }
      }
      setSearchfieldtext(input)
      setShowingValues(showingValuesList)
    }
  }

  const handleClickDropdownItem = (value: any) => {
    var valueIndex = chosenValues.indexOf("'" + value + "'")
    var valuesToSet: any = []

    if (value === "Nothing") valuesToSet = []
    else if (value === "All") valuesToSet = showingValues.map(item => item[prop])
    else {
      if (valueIndex === -1) {
        if (chosenValues.indexOf("All") !== -1) valuesToSet = [value]
        else {
          valuesToSet = chosenValues.includes(value)
            ? chosenValues.filter((val: any) => val !== value)
            : chosenValues.concat(value)
        }
      } else {
        valuesToSet = chosenValues.filter(
          (item: string) => item !== "'" + value + "'" && item !== value,
          searchfieldtext
        )
      }
    }

    if (valuesToSet.length >= maxItems) {
      setChosenValues(valuesToSet.slice(0, maxItems))
      valuesToSet = valuesToSet.slice(0, maxItems)
    } else setChosenValues(valuesToSet)

    if (chosenValues === allLocalProps) {
      setChosenValues(null)
      updateOptions(["_"])
    } else {
      var optionsSelected = JSON.parse(JSON.stringify(valuesToSet))
      optionsSelected = sanitizeSelection(optionsSelected)
      setChosenValues(optionsSelected)
      updateOptions(optionsSelected)
    }
  }

  const filter = useCallback((options: string | any[], searchfieldtext: string) => {
    const showingValuesList = [] as any
    for (var i = 0; options && i < options.length; i++) {
      if (options[i] &&
        (options[i][prop])?.toString().toLowerCase().includes(searchfieldtext.toLowerCase())
      )
        showingValuesList[showingValuesList.length] = options[i]
    }
    return showingValuesList
  }, [prop])

  const removeQuotesFromString = (s: any): string => {
    if (typeof s !== 'string') {
      return s
    }
  
    let stringLength = s.length;
    if (s.charAt(0) === "'") s = s.substring(1, stringLength--)
    if (s.charAt(--stringLength) === "'") s = s.substring(0, stringLength)
  
    return s
  }

  const sanitizeSelection = (optionsSelected: any) => {
    optionsSelected.forEach((option: any, index: any) => {
      if (optionsSelected[index].indexOf("'") > 0) {
        option = option.replace("'", "''")
      }
      if (
        optionsSelected[index].indexOf("'") !== 0 &&
        optionsSelected[index] !== "_"
      )
        optionsSelected[index] = "'" + option + "'"
    })
    if (optionsSelected === "") optionsSelected = "_"

    return optionsSelected
  }

  useEffect(() => {
    if (options !== allLocalProps) {
      setShowingValues(filter(options, searchfieldtext))
      setAllLocalProps(options)
    }
    if (chosenValues !== props.chosenValues) {
      setChosenValues(props.chosenValues[0] === "_" ? [] : props.chosenValues)
    }
    document.addEventListener("mousedown", outSideClick)
    return () => {
      document.removeEventListener("mousedown", outSideClick)
    }
    // eslint-disable-next-line
  }, [options, allLocalProps])

  function outSideClick(event: any) {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target))
      setIsDropdownOpen(false)
  }

  const showChosenValues =
    props.chosenValues && props.chosenValues[0] === "_"
      ? []
      : props.chosenValues.map((s) => removeQuotesFromString(s))

  return (
    <div
      ref={dropdownRef}
      onMouseDown={(event) => outSideClick(event)}
      className={
        "dropdown dropdown-multi dropdown" +
        (isDropdownOpen ? "--active" : "--inactive")
      }
    >
      <button
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        className={
          "dropdown__button " +
          (showChosenValues.length > 0 ? "dropdown__button_selected" : "")
        }
      >
        <p>
          {showChosenValues && showChosenValues.length
            ? (/^true$/i.test("false") ? title +
             ": " : "") +
              (showChosenValues.length > 1
                ? showChosenValues.length.toString() +
                  " items" +
                  (showChosenValues.length === maxItems ? " (max)" : "")
                : showChosenValues.join(", "))
            : title}
          <span
            className={`icon bi bi-chevron-${props.dropdown ? "up" : "down"}`}
          ></span>
        </p>
      </button>
      <div className="dropdown__list">
        <div className="hintText">
          <div className="">Select one or more items</div>
          <button
            className="icon bi bi-x-lg closeButton"
            onClick={(event) => setIsDropdownOpen(false)}
          ></button>
        </div>
        <div className="searchContainer">
          <input
            onKeyUp={(e) => handleKeyDownOnInput(e)}
            className="searchField"
            placeholder="Search"
            type="text"
          />
          <span className="icon bi bi-search searchIcon" />
          <button
            onClick={() => handleClickDropdownItem("Nothing")}
            className="bi bi-arrow-repeat dropdowResetIcon"
          />
        </div>
        <div className="dropdownOptions">
          <li
            onClick={
              JSON.stringify(showChosenValues) !==
              JSON.stringify(showingValues.map((item) => item[prop]))
                ? (e) => handleClickDropdownItem("All")
                : (e) => handleClickDropdownItem("Nothing")
            }
            className="selectAllLi"
          >
            {JSON.stringify(showChosenValues) !==
            JSON.stringify(showingValues.map((item) => item[prop]))
              ? "Select all"
              : "Deselect all"}
          </li>
          {showingValues.map((option, index) => {
            return (
              <li
                key={index}
                className={
                  "dropdownListItem " +
                  (showChosenValues.indexOf(option[prop]) >= 0
                    ? "selected_dropdownListItem"
                    : "")
                }
                onClick={() => handleClickDropdownItem(option[prop])}
              >
                {option[prop]}
                <img
                  src={icon}
                  alt=""
                  className={
                    showChosenValues.indexOf(option[prop]) >= 0
                      ? "iconVisible"
                      : "iconInvisible"
                  }
                  height="24"
                  width="24"
                ></img>
              </li>
            );
          })}
        </div>
      </div>
    </div>
  );
}
