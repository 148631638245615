import React, { useState, useEffect, useMemo, ReactElement, Dispatch, SetStateAction, PropsWithChildren } from 'react';
import { DataOutputPhase, DataOutputState, IStudy, IStudyPhase, IStudyStage, IStudyState, StudyClassification } from './@types/study';
import { IDataPartner } from './@types/datapartner';
import useAsyncSetter from './helpers/useAsyncSetter';

export interface IDataOutput {
  state: IStudyState;
  phase: IStudyPhase;
  ongoing: boolean;
  completed: boolean;
  phaseSeparated: boolean;
  classificationSeparated: boolean;
  average: boolean;
  total: boolean;
}

export interface IFilters {
  studyName: string;
  studyNumber: string;
  studyType: Array<string>;
  studyStage: string;
  dataPartner: string;
  dataOutput: IDataOutput;
}

export enum ChartColorPalette {
  PALETTE1  = "PALETTE1",
  PALETTE2  = "PALETTE2",
  PALETTE3  = "PALETTE3",
  PALETTE4  = "PALETTE4",
  PALETTE5  = "PALETTE5",
  PALETTE6  = "PALETTE6",
  PALETTE7  = "PALETTE7",
  PALETTE8  = "PALETTE8",
  PALETTE9  = "PALETTE9",
  PALETTE10 = "PALETTE10",
  PALETTE11 = "PALETTE11",
  PALETTE12 = "PALETTE12",
  PALETTE13 = "PALETTE13",
  PALETTE14 = "PALETTE14",
  PALETTE15 = "PALETTE15",
}

export const ColorPalette = { 
  PALETTE1:	 [ "#008FFB", "#00E396", "#FEB019", "#FF4560", "#775DD0", "#C7F464", "#81D4FA" ],
  PALETTE2:	 [ "#3F51B5", "#03A9F4", "#4CAF50", "#F9CE1D", "#FF9800", "#C7F464", "#81D4FA" ],
  PALETTE3:	 [ "#33B2DF", "#546E7A", "#D4526E", "#13D8AA", "#A5978B", "#C7F464", "#81D4FA" ],
  PALETTE4:	 [ "#4ECDC4", "#C7F464", "#81D4FA", "#546E7A", "#FD6A6A", "#F9A3A4", "#90EE7E" ],
  PALETTE5:	 [ "#2B908F", "#F9A3A4", "#90EE7E", "#FA4443", "#69D2E7", "#C7F464", "#81D4FA" ],
  PALETTE6:	 [ "#449DD1", "#F86624", "#EA3546", "#662E9B", "#C5D86D", "#C7F464", "#81D4FA" ],
  PALETTE7:	 [ "#D7263D", "#1B998B", "#2E294E", "#F46036", "#E2C044", "#C7F464", "#81D4FA" ],
  PALETTE8:	 [ "#662E9B", "#F86624", "#F9C80E", "#EA3546", "#43BCCD", "#C7F464", "#81D4FA" ],
  PALETTE9:	 [ "#5C4742", "#A5978B", "#8D5B4C", "#5A2A27", "#C4BBAF", "#C7F464", "#81D4FA" ],
  PALETTE10: [ "#A300D6", "#7D02EB", "#5653FE", "#2983FF", "#00B1F2", "#C7F464", "#81D4FA" ],
  PALETTE11: [ "#19b44c", "#eb9a02", "#5653FE", "#157a7e", "#2955b4", "#9900e0", "#c71d50" ],
  PALETTE12: [ "#f1d348", "#223596", "#74abdb", "#e67e22", "#2ecc71", "#9b59b6", "#34495e" ],
  PALETTE13: [ "#74abdb", "#223596", "#f1d348", "#16a085", "#2980b9", "#d35400", "#7f8c8d" ],
  PALETTE14: [ "#f1d348", "#e74c3c", "#74abdb", "#27ae60", "#223596", "#f39c12", "#8e44ad" ],
  PALETTE15: [ "#172074", "#2e457b", "#223596", "#b2920c", "#f1d348", "#f2e6a2", "#74abdb" ]
}

export const filterDefaults: IFilters = {
  studyName: '',
  studyNumber: '',
  studyType: [],
  studyStage: '',
  dataPartner: '',
  dataOutput: {
    state: {
      active: false,
      inactive: false,
      both: false,
    },
    phase: {
      all: false,
      phase1: false,
      phase2: false,
      phase3: false,
    },
    ongoing: true,
    completed: true,
    phaseSeparated: false,
    classificationSeparated: false,
    average: false,
    total: true
  }
};

export interface StandardEnum<T> {
  [id: string]: T | string;
  [nu: number]: string;
};

export const toEnum = <T, K extends StandardEnum<T>>(type: StandardEnum<T>, value: K[keyof K]): T | null => {
  const keys = Object.keys(type);
  const values = Object.values(type)
    .filter((value) => {
      return !(typeof value === "string" && keys.includes(value) && type[value] !== value)
    });

  return values.includes(value) ? (value as unknown as T) : null;
};

export interface IAppContextProps {
  selectedStudy: IStudy | null;
  setSelectedStudy: Dispatch<SetStateAction<IStudy | null>>;
  studies: IStudy[] | null;
  setStudies:(newValue: IStudy[] | null) => Promise<any>;
  studyStages: IStudyStage[] | null;
  setStudyStages: Dispatch<SetStateAction<IStudyStage[] | null>>;
  dataPartners: IDataPartner[] | null;
  setDataPartners: Dispatch<SetStateAction<IDataPartner[] | null>>;
  title: React.ReactElement | null;
  setTitle: Dispatch<SetStateAction<React.ReactElement | null>>;
  filterByStudyName: IStudy | null;
  setFilterByStudyName: (newValue: IStudy | null) => Promise<any>;
  filterByStudyNumber: IStudy | null;
  setFilterByStudyNumber: Dispatch<SetStateAction<IStudy | null>>;
  filterByStudyType: Array<string> | null;
  setFilterByStudyType: Dispatch<SetStateAction<Array<string> | null>>;
  filterByStudyStage: IStudyStage | null;
  setFilterByStudyStage: (newValue: IStudyStage | null) => Promise<any>;
  filterByDataPartner: IDataPartner | null;
  setFilterByDataPartner:  (newValue: IDataPartner | null) => Promise<any>;
  filters: IFilters;
  setFilters: Dispatch<SetStateAction<IFilters>>;
  stateFilter: DataOutputState | null;
  setStateFilter: Dispatch<SetStateAction<DataOutputState | null>>;
  phaseFilter: DataOutputPhase | null;
  setPhaseFilter: Dispatch<SetStateAction<DataOutputPhase | null>>;
};

export const AppContext = React.createContext<IAppContextProps | null>(null);

const AppProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [studies, setStudies] = useAsyncSetter<IStudy[] | null>(null);
  const [studyStages, setStudyStages] = useState<IStudyStage[] | null>(null);
  const [dataPartners, setDataPartners] = useState<IDataPartner[] | null>(null);
  const [selectedStudy, setSelectedStudy] = useState<IStudy | null>(null);
  const [title, setTitle] = useState<ReactElement | null>(<div>Home</div>);
  const [filterByStudyName, setFilterByStudyName] = useAsyncSetter<IStudy | null>(null);
  const [filterByStudyNumber, setFilterByStudyNumber] = useState<IStudy | null>(null);
  const [filterByStudyType, setFilterByStudyType] = useState<Array<string> | null>(StudyClassification.filter(record => record.key !== 157530999).map(item => `'${item.name}'`));
  const [filterByStudyStage, setFilterByStudyStage] = useAsyncSetter<IStudyStage | null>(null);
  const [filterByDataPartner, setFilterByDataPartner] = useAsyncSetter<IDataPartner | null>(null);
  const [filters, setFilters] = useState<IFilters>(filterDefaults);
  const [stateFilter, setStateFilter] = useState<DataOutputState | null>(DataOutputState.both);
  const [phaseFilter, setPhaseFilter] = useState<DataOutputPhase | null>(DataOutputPhase.all);

  useEffect(() => {
    if (selectedStudy) setSelectedStudy(selectedStudy);
    if (studies) setStudies(studies);
    if (studyStages) setStudyStages(studyStages);
    if (title) setTitle(title);
    if (filterByStudyNumber) setFilterByStudyNumber(filterByStudyNumber);
    if (filters) setFilters(filters);
    if (stateFilter) setStateFilter(stateFilter);
    if (phaseFilter) setPhaseFilter(phaseFilter);
  }, [
    selectedStudy,
    studies,
    setStudies,
    studyStages,
    dataPartners, 
    title,
    filterByStudyName,
    filterByStudyNumber,
    filterByStudyType,
    filterByStudyStage,
    filterByDataPartner,
    filters,
    stateFilter,
    phaseFilter
  ]);

  const value = useMemo(
    () => ({
      selectedStudy,
      setSelectedStudy,
      studies,
      setStudies,
      studyStages,
      setStudyStages,
      dataPartners, 
      setDataPartners,
      title,
      setTitle,
      filterByStudyName,
      setFilterByStudyName,
      filterByStudyNumber,
      setFilterByStudyNumber,
      filterByStudyType,
      setFilterByStudyType,
      filterByStudyStage,
      setFilterByStudyStage,
      filterByDataPartner,
      setFilterByDataPartner,
      filters,
      setFilters,
      stateFilter,
      setStateFilter,
      phaseFilter,
      setPhaseFilter
    }), 
    [
      selectedStudy,
      studies,
      setStudies,
      studyStages,
      dataPartners, 
      title,
      filterByStudyName,
      setFilterByStudyName,
      filterByStudyNumber,
      filterByStudyType,
      filterByStudyStage,
      setFilterByStudyStage,
      filterByDataPartner,
      setFilterByDataPartner,
      filters,
      stateFilter,
      phaseFilter
    ]
  );

  return (
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
