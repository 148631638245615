import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useMsal } from '@azure/msal-react';
import { a11yProps, TabPanel } from "../../components/TabPanel/TabPanel";
import { SyntheticEvent, useContext, useEffect, useRef, useState } from "react";
import CustomHighcharts from "../../components/CustomHighcharts/CustomHighcharts";
import PortfolioFilters from "../../components/PortfolioFitlers/PortfolioFilters";
import { IStudyResponse, IStudy, IStudyStageResponse, StudyClassification } from "../../@types/study";
import { IDatapartnerResponse, IDataPartner } from "../../@types/datapartner";
import { AppContext, IAppContextProps, IFilters } from "../../context";
import { deepEqual } from "../../helpers/utils";
import { globalConfig } from "../../auth";

import { data01 } from './data/data01';
import { data02 } from './data/data02';
import { data03 } from './data/data03';
import { data04, processData as processData04 } from './data/data04';
import { data05 } from './data/data05';
import { data06 } from './data/data06';
import { data07 } from './data/data07';
import { data08 } from './data/data08';
import { data09, processData as processData09 } from './data/data09';
import { data10, processData as processData10 } from "./data/data10";
import { data11, processData as processData11 } from "./data/data11";
import { data12, processData as processData12 } from "./data/data12";
import { data13, processData as processData13 } from "./data/data13";
import "./Portfolio.scss";

export const Portfolio = () => {

  // eslint-disable-next-line
  const { studies, setStudies, studyStages, setStudyStages, setDataPartners, filterByStudyName, filterByStudyNumber, filterByStudyType, setFilterByStudyStage, filterByDataPartner, filters, setFilters } = useContext(AppContext) as IAppContextProps;
  const { instance, accounts } = useMsal();

  // eslint-disable-next-line
  const [props01, setProps01] = useState<any>(data01);

  // eslint-disable-next-line
  const [props02, setProps02] = useState<any>(data02);

  // eslint-disable-next-line
  const [props03, setProps03] = useState<any>(data03);

  // eslint-disable-next-line
  const [props04, setProps04] = useState<any>(data04);

  // eslint-disable-next-line
  const [props05, setProps05] = useState<any>(data05);

  // eslint-disable-next-line
  const [props06, setProps06] = useState<any>(data06);

  // eslint-disable-next-line
  const [props07, setProps07] = useState<any>(data07);

  // eslint-disable-next-line
  const [props08, setProps08] = useState<any>(data08);

  // eslint-disable-next-line
  const [props09, setProps09] = useState<any>(data09);

  // eslint-disable-next-line
  const [props10, setProps10] = useState<any>(data10);

  // eslint-disable-next-line
  const [props11, setProps11] = useState<any>(data11);

  // eslint-disable-next-line
  const [props12, setProps12] = useState<any>(data12);

  // eslint-disable-next-line
  const [props13, setProps13] = useState<any>(data13);
  const initialized = useRef(false)

  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(0);

  const [currentFilter, setCurrentFilter] = useState<IFilters | null>(null);

  props04.ref = useRef(null);
  props09.ref = useRef(null);
  props10.ref = useRef(null);
  props11.ref = useRef(null);
  props12.ref = useRef(null);
  props13.ref = useRef(null);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  
  const request = {
    ...globalConfig,
    scopes: ["https://darwin-eu-prod.crm4.dynamics.com/.default"],
    account: accounts[0]
  };
  
  const buildFilter = (): { serviceSideProcessing: string, clientSideProcessing: ((data: IStudy[] | null) => IStudy[])[] } => {
    let filtersA: string[] = [];
    let functionsA: ((data: IStudy[] | null) => IStudy[])[] = [];

    if (filters?.dataOutput.completed === false && filters?.dataOutput.ongoing === false) {
      filtersA.push(`(darwineu_study_studycompleted eq null)`);
    } else if (filters?.dataOutput.completed === true || filters?.dataOutput.ongoing === true) {
      if (filters?.dataOutput.completed === true && filters?.dataOutput.ongoing !== true) {
          filtersA.push(`(darwineu_study_studycompleted eq true)`);
      } else if (filters?.dataOutput.ongoing === true && filters?.dataOutput.completed !== true) {
          filtersA.push(`(darwineu_study_studycompleted eq false)`);
      }
    } 
    
    if (filters?.studyStage) {
      filtersA.push(`darwineu_study_activephase eq '${filters?.studyStage}'`);
    }

    if (filters?.dataOutput.state.both === true) {
      filtersA.push(`(statecode eq 0 or statecode eq 1)`);
    } else if (filters?.dataOutput.state.active === true) {
      filtersA.push(`statecode eq 0`); 
    } else if (filters?.dataOutput.state.inactive === true) {
      filtersA.push(`statecode eq 1`);
    }

    if (filters?.dataOutput.phase.all === true) {
      filtersA.push(`(darwineu_study_studynumber ne null or darwineu_study_studyrequestid ne null)`);
    } else if (filters?.dataOutput.phase.phase1 === true) {
      filtersA.push(`contains(darwineu_study_studynumber,'P1-') or contains(darwineu_study_studyrequestid,'P1-')`);
    } else if (filters?.dataOutput.phase.phase2 === true) {
      filtersA.push(`contains(darwineu_study_studynumber,'P2-') or contains(darwineu_study_studyrequestid,'P2-')`);
    } else if (filters?.dataOutput.phase.phase3 === true) {
      filtersA.push(`contains(darwineu_study_studynumber,'P3-') or contains(darwineu_study_studyrequestid,'P3-')`);
    }

    if (filterByStudyName?.darwineu_studyid) {
      filtersA.push(`(darwineu_studyid eq '${filterByStudyName?.darwineu_studyid}')`);
    }

    if (filters?.dataPartner) {
      functionsA.push((data: IStudy[] | null): IStudy[] => {
        if (!data) return [];
        return data
          .map((study: IStudy) => {
            const filteredStages =
              study.darwineu_Study_x_StudyStage?.filter((stage: any) => {
                return (stage.darwineu_Database?.crfd9_datapartnername === filters.dataPartner);
              }) ?? [];
            return { ...study, darwineu_Study_x_StudyStage: filteredStages };
          })
          .filter((study: IStudy) => study.darwineu_Study_x_StudyStage && study.darwineu_Study_x_StudyStage.length > 0);
      });
    }
  
    if (filterByStudyType && filterByStudyType?.length > 0) {
      const keys = StudyClassification.filter(item => filterByStudyType.map(type => type.replace(/'/g, "")).includes(item.name)).map(item => item.key);
      filtersA.push(`(${keys.map(key => `(darwineu_study_result eq ${key})`).join(" or ")})`);
    }
    
    return { 
      serviceSideProcessing: filtersA.join(' and '), 
      clientSideProcessing: functionsA
    }
  }

  const fetchDataPartners = async (token: string): Promise<IDataPartner[]> => {
    const response = await fetch("https://darwin-eu-prod.crm4.dynamics.com/api/data/v9.1/darwineu_datapartners", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json() as IDatapartnerResponse;
    return data.value as IDataPartner[];
  };

  const fetchAllStudies = async (token: string): Promise<IStudy[]> => {
    const response = await fetch(`https://darwin-eu-prod.crm4.dynamics.com/api/data/v9.2/darwineu_studies`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = await response.json() as IStudyResponse;
    return data.value as IStudy[];
  }

  const fetchStudies = async (token: string): Promise<IStudy[]> => {
    const response = await fetch(`https://darwin-eu-prod.crm4.dynamics.com/api/data/v9.2/darwineu_studies`, { // ?$expand=darwineu_Study_x_StudyStage($expand=darwineu_Database($select=))&$filter=darwineu_Study_x_StudyStage/any(o1:o1/darwineu_studystageid ne null)
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = await response.json() as IStudyResponse;
    return data.value as IStudy[];
  }

  const fetchStudiesWithStages = async (token: string): Promise<IStudy[]> => {
    const response = await fetch(`https://darwin-eu-prod.crm4.dynamics.com/api/data/v9.1/darwineu_studies?$expand=darwineu_Study_x_StudyStage($expand=darwineu_Database($select=))&$filter=darwineu_Study_x_StudyStage/any(o1:o1/darwineu_studystageid ne null)`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return data.value as IStudy[];
  };

  const fetchStudiesWithFilter = async (token: string, encodedFilter: string): Promise<IStudy[]> => {
    const response = await fetch(`https://darwin-eu-prod.crm4.dynamics.com/api/data/v9.1/darwineu_studies?$filter=${encodedFilter}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return data.value as IStudy[];
  };

  const fetchData = async (filters: IFilters) => {
    setLoading(true);
    
    setProps04({ ...props04, loading: true });
    setProps09({ ...props09, loading: true });
    setProps10({ ...props10, loading: true });
    setProps11({ ...props11, loading: true });
    setProps12({ ...props12, loading: true });
    setProps13({ ...props13, loading: true });

    const fetchStudiesFiltered = async () => {
      try {
        const response = await instance.acquireTokenSilent(request);
        const token = response.accessToken;
        const filter = buildFilter();
        const encodedFilter = encodeURIComponent(filter.serviceSideProcessing);

        setStudies(await fetchStudies(token));

        // fetch studies with darwineu_Study_x_StudyStage expanded
        const studiesWithStages = await fetchStudiesWithStages(token);
    
        // fetch studies with a specific filter
        const filteredStudies = await fetchStudiesWithFilter(token, encodedFilter);
        const filteredIds = new Set(filteredStudies.map(study => study.darwineu_studyid));
        const mergedFilteredStudies = studies?.filter((study: IStudy) => filteredIds.has(study.darwineu_studyid));
        const stagesMap: Map<string, IStudyStageResponse[] | null> = new Map(studiesWithStages.map(study => [study.darwineu_studyid, study.darwineu_Study_x_StudyStage]));
        let mergedStudiesWithStages: IStudy[] | null = mergedFilteredStudies?.map((study: IStudy) => {
          const stagesOrFallback = stagesMap.get(study.darwineu_studyid) ?? null;
          return { ...study, darwineu_Study_x_StudyStage: stagesOrFallback };
        }) ?? null;
        
        filter.clientSideProcessing.forEach(processFunction => {
          if (mergedStudiesWithStages) {
            mergedStudiesWithStages = processFunction(mergedStudiesWithStages)
          }
        })

        processData04({...props04, filters: filters}, mergedStudiesWithStages ?? filteredStudies, (val: any) => setProps04(val));
        processData09({...props09, filters: filters}, mergedStudiesWithStages ?? filteredStudies, (val: any) => setProps09(val));
        processData10({...props10, filters: filters}, mergedStudiesWithStages ?? filteredStudies, (val: any) => setProps10(val));
        processData11({...props11, filters: filters}, mergedStudiesWithStages ?? filteredStudies, (val: any) => setProps11(val));
        processData12({...props12, filters: filters}, mergedStudiesWithStages ?? filteredStudies, (val: any) => setProps12(val));
        processData13({...props13, filters: filters}, mergedStudiesWithStages ?? filteredStudies, (val: any) => setProps13(val));

        setLoading(false);

      } catch (error) {
        console.error("Error fetching studies:", error);
      }
    };

    if (!initialized.current) {
      initialized.current = true
      const response = await instance.acquireTokenSilent(request);
      const token = response.accessToken;

      setDataPartners(await fetchDataPartners(token));
      setStudies(await fetchAllStudies(token));
      await fetchStudies(token);
    }

    fetchStudiesFiltered();
  };

  useEffect(() => {
    if (!initialized.current) {
      fetchData(filters)
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (currentFilter === null) setCurrentFilter(filters);
    else if (!deepEqual(currentFilter, filters)) {
      setCurrentFilter(filters);
      fetchData(filters);
    }
    // eslint-disable-next-line
  }, [filters])

  const updateFiltersCallback = (data: any) => { };

  return (
    <div className="page portfolio">
      <Box component="main"
        sx={{ flexGrow: 1, p: 3, background: "white", color: "black" }}
        >
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs">
          <Tab label="Overview" {...a11yProps(0)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <div className="filters">
          <span className="label">Filters</span>
          <PortfolioFilters updateFiltersCallback={(e: any) => updateFiltersCallback(e) } />
        </div>
        <div className="tab-content">
          <h3>Portfolio</h3>
          <div className="main-container">
            <div className="home-container">
              <div className="list">
                <CustomHighcharts ref={props11.ref} id={props11.options.chart.id} isLoading={props11.loading} config={props11} type="bar" />
                <CustomHighcharts ref={props12.ref} id={props12.options.chart.id} isLoading={props12.loading} config={props12} type="boxplot" />
                <CustomHighcharts ref={props13.ref} id={props13.options.chart.id} isLoading={props13.loading} config={props13} type="pie" />
                <CustomHighcharts ref={props09.ref} id={props09.options.chart.id} isLoading={props09.loading} config={props09} type="bar" />
                <CustomHighcharts ref={props04.ref} id={props04.options.chart.id} isLoading={props04.loading} config={props04} type="bar" />
                <CustomHighcharts ref={props10.ref} id={props10.options.chart.id} isLoading={props10.loading} config={props10} type="boxplot" />
              </div>
            </div>
          </div>
        </div>
      </TabPanel>
    </div>
  );
};
