export const data02 = {
  loading: true,
  options: {
    chart: {
      type: "bar",
      zoomType: "xy",
    },
    series: [
      {
        data: [1, 2, 3],
      },
    ],
  },
};
