import React, { Component } from "react"

import "./LoadingOverlay.scss"

class App extends Component {
  render() {
    return (
      <div
        className={
          "loader " + (this.props.isLoading ? "show-loading" : "hide-loading") + " " + (this.props.textOnly ? "text-only" : "") + " " + (this.props.hideText === true ? "hide-text" : "")
        }
      >
        <div className="sk-chase">
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
        </div>
        <span>{this.props.text ? this.props.text : "Loading"}</span>
      </div>
    )
  }
}

export default App
