import React, { useEffect, useState, } from "react";
import { ThemeProvider, createTheme } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import { HashRouter as Router } from 'react-router-dom';
import { useIsAuthenticated } from "@azure/msal-react";
import { DrawerHeader } from "./components/DrawerHeader/DrawerHeader";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import SideBar from "./components/SideBar/SideBar";
import { Home } from './pages/home/Home';
import { Study } from './pages/study/Study';
import { Portfolio } from './pages/portfolio/Portfolio';
import { Faq } from './pages/faq/Faq';
import { Login } from './pages/login/Login';
import { Logout } from './components/Logout/Logout';
import 'moment/locale/nl';
import "./app.scss";

import star from './img/star.png';

interface ICustomStyle extends React.CSSProperties {
  '--star-image': string;
}

const THEME = createTheme({
  components: {
    MuiTable: {
      styleOverrides: {
        root: {
          borderCollapse: "unset",
        },
      },
    },
  },
  shape: { borderRadius: 4 },
  typography: {
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  palette: {
    primary: {
      main: "#004494",
    },
  },
});

function App() {
  const [initialized, setInitialized] = useState(false);
  
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
    }
  }, [initialized]);

    // Workaround for MSAL issue, see below.
  // https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/5807
  useEffect(() => {
    const itemKey = "msal.interaction.status";
    if (sessionStorage.getItem(itemKey)) {
      sessionStorage.removeItem(itemKey);
    }
  }, []);

  const customStyle: ICustomStyle = {
    '--star-image': `url(${star})`
  };

  return (
    <>
      {!useIsAuthenticated() ? (
        <Login />
      ) : (
        <div className="app">
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="nl">
            <ThemeProvider theme={THEME}>
              <Router>
                  <div className="sidebar">
                    <div className="menu">
                        <SideBar />
                        <DrawerHeader />
                      </div>
                  </div>
                  <div className="main-content">
                    <div className="main">
                      <Routes>
                        <Route path="/" element={<div style={customStyle}><Home /></div>} />
                        <Route path="/study" element={<div style={customStyle}><Study /></div>} />
                        <Route path="/portfolio" element={<div><Portfolio /></div>} />
                        <Route path="/faq" element={<div style={customStyle}><Faq /></div>} />
                        <Route path="/logout" element={<Logout />} />
                      </Routes>
                    </div>       
                  </div>

              </Router>
            </ThemeProvider>
          </LocalizationProvider>
        </div>
      )}
    </>
  );
}

export default App;
