import React from "react";
import { useMsal } from "@azure/msal-react";
import { defaultConfig } from "../../auth";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

const LoginButton = () => {
    const { instance } = useMsal();

    const handleLogin = (loginType) => {
        if(loginType === "popup") {
            instance.loginPopup(defaultConfig).catch(e => {
                console.error(e);
            });
        } else if (loginType === "redirect") {
            instance.loginRedirect(defaultConfig).catch(e => {
                console.error(e);
            });
        }
    }

    return (
        <div className="login-button-container">
            <Stack spacing={2} direction="row">
                <Button className="login-button" variant="contained" color="success" onClick={() => handleLogin("popup")}>Sign In</Button>
            </Stack>
        </div>
    )
};

export default LoginButton;