import { ChartColorPalette, ColorPalette, IFilters } from "../../../context";
import moment from "moment";

const calculateMedian = (values: any[]) => {
  if (values.length === 0) return 0;
  values.sort((a, b) => a - b);
  const half = Math.floor(values.length / 2);
  if (values.length % 2) return values[half];
  return (values[half - 1] + values[half]) / 2.0;
};

const calculateQuartiles = (daysArray: any[]) => {
  const median = calculateMedian(daysArray);
  const lowerHalf = daysArray.filter((day) => day < median);
  const upperHalf = daysArray.filter((day) => day > median);
  const min = daysArray[0];
  const max = daysArray[daysArray.length - 1];
  const lowerQuartile = calculateMedian(lowerHalf);
  const upperQuartile = calculateMedian(upperHalf);
  return [min, lowerQuartile, median, upperQuartile, max];
};

export const processData = (props: any, data: any, callback: Function) => {
  
  // !! use filters.dataOutput.phaseSeparated !!
  // eslint-disable-next-line
  const filters = props.filters;
  const categorizedPerStage: any = {};

  const studyStages = {
    Feasibility: { startField: "darwineu_study_datefeasibilityrequestbyema", endField: "darwineu_study_dateapprovaloffeasibility", },
    Initiation: { startField: "darwineu_study_initiationstartdate", endField: "darwineu_study_dateapprovalofinitiation", },
    Implementation: { startField: "darwineu_study_implementationstartdate", endField: "darwineu_study_implfinalprotocolapprovedbyemadate", },
    Execution: { startField: "darwineu_study_executionstartdate", endField: "darwineu_study_dateapprovalofexecution", },
    Dissemination: { startField: "darwineu_study_disseminationstartdate", endField: "darwineu_study_disseminationresultssubmitted", },
  };

  data.forEach((study: any) => {
    Object.entries(studyStages).forEach(([stage, { startField, endField }]) => {
      const startDate = study[startField] ?? null;
      const endDate = study[endField] ?? null;
      if (startDate && endDate) {
        const duration = moment(endDate).diff(moment(startDate), "days");
        if (duration > 0 || true) {
          if (!categorizedPerStage[stage]) {
            categorizedPerStage[stage] = [];
          }
          categorizedPerStage[stage].push(duration > 0 ? duration : 1);
        }
      }
    });
  });

  const seriesData = Object.entries(categorizedPerStage).map(([stage, durations]: [any, any]) => {
    const sortedDurations = durations.sort((a: number, b: number) => a - b);
    const [min, lowerQuartile, median, upperQuartile, max] = calculateQuartiles(sortedDurations);
    return {
      name: stage,
      data: [[min, lowerQuartile, median, upperQuartile, max]],
    };
  });

  callback({
    loading: false,
    options: {
      ...props.options,
      xAxis: {
        ...props.xAxis,
        categories: Object.keys(studyStages),
      },
      series: [
        {
          name: "Duration (Days)",
          data: seriesData.map((item) => item.data[0]),
          pointWidth: 35,
          tooltip: {
            headerFormat: "",
            borderWidth: 0,
            shadow: true,
            useHTML: true,
            style: {
              padding: 0,
            },
          },
        },
      ],
    },
  });

  if (props.ref?.current?.ref !== null) {
    props.ref?.current?.ref?.current.chart.update({ chart: {} });
  }
};

export const data12 = {
  ref: null,
  loading: true,
  filters: null as any as IFilters,
  options: {
    chart: {
      id: "chart12",
      type: "boxplot",
      zoomType: "xy",
      inverted: true,
    },
    noData: {
      useHTML: true,
    },
    lang: {
      noData: '<div style="color: var(--darwin-yellow)">[ NO DATA ]</div>',
    },
    title: {
      text: "Average stage duration per stage (days)",
      align: "center",
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      categories: [],
    },
    yAxis: {
      title: {
        text: "",
      },
    },
    tooltip: {
      shared: true,
    },
    marker: {
      fillColor: "white",
      lineWidth: 10,
      lineColor: ColorPalette[ChartColorPalette.PALETTE13][0],
    },
    plotOptions: {
      boxplot: {
        boxDashStyle: "Dash",
        fillColor: ColorPalette[ChartColorPalette.PALETTE13][0],
        lineWidth: 0.5,
        medianColor: ColorPalette[ChartColorPalette.PALETTE13][4],
        medianDashStyle: "ShortDot",
        medianWidth: 3,
        stemColor: ColorPalette[ChartColorPalette.PALETTE13][4],
        stemDashStyle: "dot",
        stemWidth: 1,
        whiskerColor: ColorPalette[ChartColorPalette.PALETTE13][2],
        whiskerLength: "50%",
        whiskerWidth: 3,
        pointPadding: 0.4,
        groupPadding: 0,
      },
      series: {
        grouping: false,
        pointRange: 1,
        pointPadding: 0.4,
        groupPadding: 0,
        tooltip: {
          followPointer: true,
        },
        states: {
          hover: {
            lineWidthPlus: 0,
          },
        },
      },
    },
    navigator: {
      handles: {
        // lineWidth: 1,
        width: 20,
        height: 30,
      },
      maskFill: "rgba(78, 125, 217, 0.2)",
      outlineWidth: 0,
      enabled: true,
      xAxis: {},
      yAxis: {
        type: "logarithmic",
      },
    },
    series: [],
  },
};
