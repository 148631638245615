import { IStudyDurations, IStudy, StudyClassification, IKeyNamePair } from "../../../@types/study";
import { ChartColorPalette, ColorPalette } from "../../../context";
import moment from "moment";

interface ITotals {
  feasibility: number;
  initiation: number;
  implementation: number;
  execution: number;
  dissemination: number;
}

interface ISeriesItem {
  name: string;
  data: number[];
}

export const processData = (props: any, data: any, callback: Function) => {
  // eslint-disable-next-line
  const durations: IStudyDurations[] = data.map((study: IStudy) => {
    const feasibilityDuration = moment(study.darwineu_study_initiationstartdate).diff(moment(study.darwineu_study_feasibilityrequestdate), "days") || 0;
    const initiationDuration = moment(study.darwineu_study_implementationstartdate).diff(moment(study.darwineu_study_initiationstartdate), "days") || 0;
    const implementationDuration = moment(study.darwineu_study_executionstartdate).diff(moment(study.darwineu_study_implementationstartdate), "days") || 0;
    const executionDuration = moment(study.darwineu_study_disseminationstartdate).diff(moment(study.darwineu_study_executionstartdate), "days") || 0;
    const disseminationDuration = moment(study.darwineu_study_disseminationresultssubmitted).diff(moment(study.darwineu_study_disseminationstartdate), "days") || 0;

    return {
      name: study.darwineu_study_studynumber,
      feasibility: feasibilityDuration,
      initiation: initiationDuration,
      implementation: implementationDuration,
      execution: executionDuration,
      dissemination: disseminationDuration,
    };
  })
  .filter((d: IStudyDurations) => 
    (d.feasibility >= 0 &&
    d.initiation >= 0 &&
    d.implementation >= 0 &&
    d.execution >= 0 &&
    d.dissemination >= 0) || false
  );

  const classificationAverages = StudyClassification.map(
    (classification: IKeyNamePair) => ({
      ...classification,
      totals: { feasibility: 0, initiation: 0, implementation: 0, execution: 0, dissemination: 0, },
      counts: 0,
    })
  );

  data.forEach((study: IStudy) => {
    const classificationIndex = classificationAverages.findIndex(c => c.key === study.darwineu_study_classification);
    if (classificationIndex !== -1) {
      const durations: ITotals = {
        feasibility: moment(study.darwineu_study_initiationstartdate).diff(moment(study.darwineu_study_feasibilityrequestdate), 'days') || 0,
        initiation: moment(study.darwineu_study_implementationstartdate).diff(moment(study.darwineu_study_initiationstartdate), 'days') || 0,
        implementation: moment(study.darwineu_study_executionstartdate).diff(moment(study.darwineu_study_implementationstartdate), 'days') || 0,
        execution: moment(study.darwineu_study_disseminationstartdate).diff(moment(study.darwineu_study_executionstartdate), 'days') || 0,
        dissemination: moment(study.darwineu_study_disseminationresultssubmitted).diff(moment(study.darwineu_study_disseminationstartdate), 'days') || 0,
      };

      Object.keys(durations).forEach((key) => classificationAverages[classificationIndex].totals[key as keyof ITotals] += durations[key as keyof ITotals]);
      classificationAverages[classificationIndex].counts += 1;
    }
  });

  const series: ISeriesItem[] = [{ name: 'Dissemination', data: [] }, { name: 'Execution', data: [] }, { name: 'Implementation', data: [] }, { name: 'Initiation', data: [] }, { name: 'Feasibility', data: [] }];
  const categories = classificationAverages.map(x => x.name);

  classificationAverages.forEach((classification) => {
    series[0].data.push(Math.round(classification.counts > 0 ? classification.totals.dissemination / classification.counts : 0));
    series[1].data.push(Math.round(classification.counts > 0 ? classification.totals.execution / classification.counts : 0));
    series[2].data.push(Math.round(classification.counts > 0 ? classification.totals.implementation / classification.counts : 0));
    series[3].data.push(Math.round(classification.counts > 0 ? classification.totals.initiation / classification.counts : 0));
    series[4].data.push(Math.round(classification.counts > 0 ? classification.totals.feasibility / classification.counts : 0));
  });

  const sumsByCategory = categories.map((_, i) => series.reduce((sum, serie) => sum + serie.data[i], 0));
  const filteredCategories = categories.filter((_, i) => sumsByCategory[i] > 0);
  const adjustedSeries = series.map(serie => ({
    ...serie,
    data: serie.data.filter((_, i) => sumsByCategory[i] > 0),
  }));

  callback({
    loading: false,
    options: {
      ...props.options,
      xAxis: {
        ...props.xAxis,
        categories: filteredCategories,
      },
      rangeSelector: {
        selected: 2,
      },
      series: adjustedSeries,
    },
  });

  if (props.ref?.current?.ref !== null) 
    props.ref?.current?.ref?.current.chart.update({ chart: { }})
};

export const data04 = {
  ref: null,
  loading: true,
  options: {
    chart: {
      id: "chart04",
      type: "bar",
      zoomType: "xy",
      height: 385,
      scrollablePlotArea: {
        minHeight: 385,
        scrollPositionX: 1,
      },
    },
    noData: { 
      useHTML: true 
    },
    lang: { 
      noData: '<div style="color: var(--darwin-yellow)">[ NO DATA ]</div>' 
    },
    navigation: {
      buttonOptions: {
        enabled: true
      }
    },
    legend: {
      reversed: true
    },
    title: {
      text: "Summary of duration in days by study type",
      align: "center",
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Average Duration (days)',
      },
    },
    xAxis: {
      categories: ['C1', 'C2', 'C3', 'C4'],
      title: {
        text: "Study Type",
      },
      stackLabels: {
        enabled: true,
      },
    },
    scrollbar: {
      enabled: true,
    },
    colors: ColorPalette[ChartColorPalette.PALETTE13],
    tooltip: {
      valueSuffix: " day(s)",
      split: true,
      shared: false,
      outside: true,
      shadow: true,
      distance: 32
    },
    plotOptions: {
      series: {
        stacking: 'normal',
      },
      bar: {
          stacking: true,
          grouping: true,
          shadow: false,
          borderWidth: 1,

          states: {
              hover: {
                  lineWidth: 5,
              },
          },
          marker: {
              enabled: false,
          },
      },
    },
    navigator: {
      handles: {
        // lineWidth: 1,
        width: 20,
        height: 30,
      },
      maskFill: 'rgba(78, 125, 217, 0.2)',
      outlineWidth: 0,
      enabled: true,
      xAxis: {},
      yAxis: {
        type: 'logarithmic',
      },
    },
    series: [],
  },
};
