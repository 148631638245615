import LoginButton from "../../components/LoginButton/LoginButton";
import DarwinLogo from "../../img/darwin-eu-logo.png";
import "./Login.scss"

export const Login = () => {
  return (
    <>
      <div className="login-wrapper">
        <div className="login-container ">
          <div className="main-header">
            <h2>DARWIN EU® Coordination Centre</h2>
            <div className="divider-outer" style={{ marginTop: "0px" }}></div>
          </div>
          <div className="main-login">
            <div className="login">
              <img
                style={{ maxHeight: "144px" }}
                className={"center"}
                src={DarwinLogo}
                alt={"DARWIN EU"}
              />
            </div>
            <div className="divider-outer" style={{ marginTop: "0px" }}></div>
            <LoginButton />
          </div>
        </div>
      </div>
      <div className="footer">Studies Dashboard <br /><span>v0.1</span></div>  
    </>
  );
};