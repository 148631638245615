import Box from "@mui/material/Box";
import "./Faq.scss"; 

export const Faq = () => {
  return (
    <Box
      component="main"
      sx={{ flexGrow: 1, p: 3, background: "white", color: "black" }}
    >
      <div className="content">
        <h3>Faq</h3>
      </div>
    </Box>
  );
};
