import React from "react";
import { useMsal } from "@azure/msal-react";

export const Logout = () => {
  const { instance } = useMsal();
  instance.logoutPopup({
    mainWindowRedirectUri: "/"
  }).catch(e => {
    console.error(e);
  });
  return <div />;
};
