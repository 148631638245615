// src/FetchStudies.js
import { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { useMsal } from '@azure/msal-react';
import { globalConfig } from '../../auth';
import { AppContext, IAppContextProps } from '../../context';
import LoadingOverlay from "../LoadingOverlay/LoadingOverlay"
// eslint-disable-next-line
import List from '@mui/material/List';
// eslint-disable-next-line
import ListItem from '@mui/material/ListItem';
// eslint-disable-next-line
import ListItemText from '@mui/material/ListItemText';
import DropdownSingle from '../DropdownSingle/DropdownSingle';

import './StudySelector.scss'
import { IStudy, IStudyResponse } from '../../@types/study';

const StudySelector = () => {
    const [loading, setLoading] = useState(false);
    
    const { instance, accounts } = useMsal();
    const { studies, setStudies, selectedStudy, setSelectedStudy } = useContext(AppContext) as IAppContextProps;

    const request = useMemo(() => ({
      ...globalConfig,
      scopes: ["https://darwin-eu-prod.crm4.dynamics.com/.default"],
      account: accounts[0],
    }), [accounts]);

    const fetchStudies = async (token: string): Promise<IStudy[]> => {
      const response = await fetch(`https://darwin-eu-prod.crm4.dynamics.com/api/data/v9.2/darwineu_studies?$expand=darwineu_Study_x_StudyStage($expand=darwineu_Database($select=))`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json() as IStudyResponse;
      return data.value as IStudy[];
    }
    
    const fetchData = useCallback(async () => {
      setLoading(true);
      const response = await instance.acquireTokenSilent(request);
      const token = response.accessToken;
      const data = await fetchStudies(token);
      setStudies(data);
      setLoading(false);
    }, [instance, request, setStudies]);
    
    useEffect(() => {
      if (!studies) fetchData();
    }, [fetchData, studies]);

    return (
      <>
        {loading ? (
          <LoadingOverlay isLoading={true} textOnly={true} text="Loading..." />
        ) : (
          <>
            {studies?.length ? (
              <div className="filterComponents">
                <DropdownSingle
                  updateOptions={setSelectedStudy}
                  title={selectedStudy?.darwineu_study_studyrequestid ?? "Select study by id..."}
                  options={
                    studies.sort((a: any, b: any) => {
                      // Extract the prefix (e.g., P1, P2) and numeric part (e.g., 001, 002)
                      const [prefixA, numA] = a.darwineu_study_studyrequestid.split('-SR-');
                      const [prefixB, numB] = b.darwineu_study_studyrequestid.split('-SR-');

                      // First, compare the prefix in ascending order
                      if (prefixA < prefixB) return -1;
                      if (prefixA > prefixB) return 1;

                      // If prefixes are the same, compare the numeric part in ascending order
                      return parseInt(numA, 10) - parseInt(numB, 10);
                    }).map((study: any) => study)
                  }
                  prop="darwineu_study_studyrequestid"
                  searchEnabled={true}
                  chosenValue={
                    selectedStudy ?? "_"
                  }
                />
                <DropdownSingle
                  updateOptions={setSelectedStudy}
                  title={selectedStudy?.darwineu_studyname ?? "Select study by name..."}
                  options={
                    studies.sort((a: any, b: any) => {
                      return a.darwineu_studyname.localeCompare(b.darwineu_studyname);
                    }).map((study: any) => study)
                  }
                  prop="darwineu_studyname"
                  searchEnabled={true}
                  chosenValue={
                    selectedStudy ?? "_"
                  }
                />
                <DropdownSingle
                  updateOptions={setSelectedStudy}
                  title={selectedStudy?.darwineu_study_studynumber ?? "Select study by number..."}
                  options={
                    studies.sort((a: any, b: any) => {
                      // Extract the prefix (e.g., P1, P2) and numeric part (e.g., 001, 002)
                      const [prefixA, numA] = a.darwineu_study_studyrequestid.split('-SR-');
                      const [prefixB, numB] = b.darwineu_study_studyrequestid.split('-SR-');

                      // First, compare the prefix in ascending order
                      if (prefixA < prefixB) return -1;
                      if (prefixA > prefixB) return 1;

                      // If prefixes are the same, compare the numeric part in ascending order
                      return parseInt(numA, 10) - parseInt(numB, 10);
                    }).map((study: any) => study)
                  }
                  prop="darwineu_study_studynumber"
                  searchEnabled={true}
                  chosenValue={
                    selectedStudy ?? "_"
                  }
                />
              </div>
            ) : (
              <p>No studies found.</p>
            )}
          </>
        )}
      </>
    );
};

export default StudySelector;