import React, { useState, useEffect, useRef, useCallback } from "react"
import { deepEqual } from "../../helpers/utils";
import icon from './icons/check.svg';
import "./DropdownSingle.scss"

type propTypes = {
  updateOptions: Function
  options: any[]
  chosenValue: any
  title: string
  firstAsDefault?: boolean
  searchEnabled?: boolean
  prop: string
}

export default function DropdownSingle(props: propTypes) {
  const { updateOptions, options, title, firstAsDefault, searchEnabled, prop } = props
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [hasDefault, setHasDefault] = useState(false)
  const [chosenValue, setChosenValue] = useState<any>([])
  const [allLocalProps, setAllLocalProps] = useState(options)
  const [showingValues, setShowingValues] = useState(options)
  const [searchfieldtext, setSearchfieldtext] = useState("")
  const dropdownRef = useRef<HTMLInputElement>(null)
  
  function handleClickDropdownItem(value: any) {
    setIsDropdownOpen(!isDropdownOpen)
    if (deepEqual(value, chosenValue) || (value === chosenValue)) {
      setChosenValue(null)
      updateOptions(["_"])
    } else {
      setChosenValue(value)
      updateOptions(value)
    }
  }

  const filter = useCallback((options: string | any[], searchfieldtext: string) => {
    const showingValuesList = [] as any
    for (var i = 0; options && i < options.length; i++) {
      if (options[i] &&
        (options[i][prop])?.toString().toLowerCase().includes(searchfieldtext.toLowerCase())
      )
        showingValuesList[showingValuesList.length] = options[i]
    }
    return showingValuesList
  }, [prop])

  useEffect(() => {
    if (options !== allLocalProps) {
      setShowingValues(filter(options, searchfieldtext))
      setAllLocalProps(options)
    }
    if (chosenValue !== props.chosenValue) {
      setChosenValue(props.chosenValue[0] === "_" ? null : props.chosenValue)
    }
    if (options && options.length > 0) {
      if (firstAsDefault && !hasDefault) { 
        setChosenValue(props.chosenValue === "_" ? null : props.chosenValue)
        updateOptions(options[0])
        setHasDefault(true)
      } 
    }

    document.addEventListener("mousedown", outSideClick)
    return () => {
      document.removeEventListener("mousedown", outSideClick)
    }
  }, [setChosenValue, setHasDefault, hasDefault, updateOptions, options, firstAsDefault, allLocalProps, chosenValue, props.chosenValue, searchfieldtext, filter])

  function outSideClick(event: any) {
    if (dropdownRef.current && !dropdownRef?.current?.contains(event.target))
      setIsDropdownOpen(false)
  }

  
  const handleKeyDownOnInput = (e: any) => {
    var input = e.target.value
    if (!e.target.value.replace(/\s/g, "").length || e.target.value === "") {
      setSearchfieldtext(input)
      setShowingValues(allLocalProps)
    }
    if (/[a-zA-Z0-9-_ ]/.test(input)) {
      const showingValuesList = [] as any
      for (var i = 0; i < allLocalProps.length; i++) {
        if (allLocalProps[i] !== null &&
          (allLocalProps[i][prop])?.toString().toLowerCase().includes(input.toLowerCase())
        ) {
          showingValuesList[showingValuesList.length] = allLocalProps[i]
        }
      }
      setSearchfieldtext(input)
      setShowingValues(showingValuesList)
    }
  }

  const removeQuotesFromString = (s: any): string => {
    if (typeof s !== 'string') {
      return s
    }
  
    let stringLength = s.length;
    if (s.charAt(0) === "'") s = s.substring(1, stringLength--)
    if (s.charAt(--stringLength) === "'") s = s.substring(0, stringLength)
  
    return s
  }

  const showChosenValue =
    props.chosenValue && props.chosenValue === "_"
      ? null
      : removeQuotesFromString(props.chosenValue[prop])

  return (
    <div 
      ref={dropdownRef}
      onMouseDown={(event) => outSideClick(event)}
      className={
        "dropdown dropdown-single dropdown" +
        (isDropdownOpen ? "--active" : "--inactive")
      }
    >
      <button
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        className={
          "dropdown__button dropdown__button " +
          (chosenValue?.length > 0 ? "dropdown__button_selected" : "")
        }
      >
        <p>
          {chosenValue && chosenValue.length
            ? chosenValue.toString()
            : title}
          <span className="icon bi bi-chevron-down" />
        </p>
      </button>
      <div className="dropdown__list ">
        {searchEnabled && (
          <>
            <div className="hintText">
              <div className="">Select an item</div>
              <button
                className="icon bi bi-x-lg closeButton"
                onClick={(event) => setIsDropdownOpen(false)}
              ></button>
            </div>
            <div className="searchContainer">
              <input
                onKeyUp={(e) => handleKeyDownOnInput(e)}
                className="searchField"
                placeholder="Search"
                type="text"
              />
              <span className="icon bi bi-search searchIcon" />
              <button
                onClick={() => handleClickDropdownItem("-")}
                className="bi bi-arrow-repeat dropdowResetIcon"
              />
            </div>
          </>
        )}
        {" "}
        <div className="dropdownOptions">
          <ul>
            {showingValues?.map((option: any, index: any) => {
              return (
                <li
                  key={index}
                  className={
                    "dropdownListItem " +
                    (chosenValue && chosenValue[prop] === option[prop] ? "selected_dropdownListItem" : "")
                  }
                  onClick={() => handleClickDropdownItem(option)}
                >
                  <div className="label">
                    {option[prop]}
                  </div>
                  <img
                    src={icon}
                    alt=""
                    className={
                      showChosenValue && showChosenValue === option[prop]
                        ? "iconVisible"
                        : "iconInvisible"
                    }
                    height="24"
                    width="24"
                  ></img>
                </li>
              )
            })}
          </ul> 
        </div> 
      </div>
    </div>
  )
}
