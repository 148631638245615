import Highcharts from "highcharts";

export const data07 = {
  loading: true,
  options: {
    chart: {
      type: "boxplot",
      zoomType: "xy",
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      categories: ["1", "2", "3", "4", "5"],
      title: {
        text: "Experiment No.",
      },
    },
    yAxis: {
      title: {
        text: "Observations",
      },
    },
    tooltip: {
      shared: true,
    },
    plotOptions: {
      series: {
        grouping: false,
        pointRange: 1,
        pointPadding: 0.4,
        groupPadding: 0,
        states: {
          hover: {
            lineWidthPlus: 0,
          },
        },
      },
    },
    series: [
      {
        name: "S1",
        data: [
          [-0.2, 755, 811, 838, 885, 955],
          [0.8, 725, 863, 930, 980, 1050],
          [1.8, 704, 752, 827, 870, 915],
          [2.8, 714, 812, 825, 871, 945],
          [3.8, 780, 826, 852, 882, 950],
        ],
        tooltip: {
          headerFormat: "<em>Experiment No {point.key}</em><br/>",
        },
      },
      {
        name: "S2",
        data: [
          [0.2, 760, 801, 848, 895, 965],
          [1.2, 733, 853, 939, 980, 1080],
          [2.2, 714, 762, 817, 870, 918],
          [3.2, 724, 802, 816, 871, 950],
          [4.2, 775, 836, 864, 882, 970],
        ],
        tooltip: {
          headerFormat: "<em>Experiment No {point.key}</em><br/>",
        },
      },
      {
        name: "Means 1",
        color: Highcharts.getOptions().colors?.[0] ?? "white",
        type: "line",
        lineWidth: 0,
        data: [
          // x, y positions where 0 is the first category
          [-0.2, 850],
          [0.8, 935],
          [1.8, 825],
          [2.8, 840],
          [3.8, 850],
        ],
        marker: {
          fillColor: Highcharts.getOptions().colors?.[0] ?? "white",
          symbol: "diamond",
          lineWidth: 1,
          lineColor: Highcharts.getOptions().colors?.[0] ?? "white",
        },
        tooltip: {
          pointFormat: "Mean: {point.y}",
        },
      },
      {
        name: "Means 2",
        color: Highcharts.getOptions().colors?.[1] ?? "red",
        type: "line",
        lineWidth: 0,
        data: [
          // x, y positions where 0 is the first category
          [0.2, 860],
          [1.2, 945],
          [2.2, 805],
          [3.2, 850],
          [4.2, 860],
        ],
        marker: {
          fillColor: Highcharts.getOptions().colors?.[1] ?? "red",
          symbol: "diamond",
          lineWidth: 1,
          lineColor: Highcharts.getOptions().colors?.[1] ?? "red",
        },
        tooltip: {
          pointFormat: "Mean: {point.y}",
        },
      },
    ],
  },
};
