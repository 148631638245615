export const msalConfig = {
    auth: {
        clientId: "api://53bafcbb-d3f0-4240-836f-b14be38bf9c4",
        authority: "https://login.microsoftonline.com/4e3f7dfb-4248-4fae-ba2a-b50a972bfb33",
        redirectUri: window.location.origin,
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true, // Recommended to use in browsers to prevent cross-site scripting attacks.
    }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const defaultConfig = {
   scopes: ["openid", "profile", "User.Read"] // Add other scopes/permissions as needed.
};

// Add this if you’re calling Microsoft Graph API
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
}

export const globalConfig = { 
    scopes: ["https://darwin-eu-prod.crm4.dynamics.com/.default"] 
};