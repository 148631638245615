import { IStudy, StudyFeasibilityResult } from "../../../@types/study";
import { ChartColorPalette, ColorPalette } from "../../../context";
import { extractYear } from "../../../helpers/utils";

export const processData = (props: any, data: any, callback: Function) => {

  // !! use filters.dataOutput.phaseSeparated !!
  // eslint-disable-next-line
  const filters = props.filters
  const categorizedPerPhase: any = {};

  data.forEach((study: IStudy) => {
    if (study.darwineu_study_studyrequestid) {
        const year = extractYear(study.darwineu_study_studyrequestid);
        if (!categorizedPerPhase[year]) {
          categorizedPerPhase[year] = [];
        }
        categorizedPerPhase[year].push(study);
      }
  });

  const getMonthNum = (dateIsoStr: any) => new Date(dateIsoStr).getMonth() + 1;
  const years = Object.keys(categorizedPerPhase).map(year => parseInt(year, 10));
  const lastYear = Math.max(...years);
  const monthlyCountsAccepted = Array(12).fill(0);
  const monthlyCountsPending = Array(12).fill(0);
  const monthlyCountsNotFeasible = Array(12).fill(0);
  const lastYearStudies = categorizedPerPhase[lastYear.toString()];
  const feasibilityKeys = StudyFeasibilityResult.reduce((acc: any, { name, key }) => {
    acc[name.trim().toUpperCase()] = key;
    return acc;
  }, {});

  lastYearStudies?.forEach((study: IStudy) => {
    const studyDateStr = study.darwineu_study_datefeasibilityrequestbyema ?? study.createdon
    const monthNum = getMonthNum(studyDateStr);
    if (study.darwineu_study_result === feasibilityKeys["FEASIBLE WITH RECOMMENDATIONS"] ||
      study.darwineu_study_result === feasibilityKeys["FEASIBLE WITH NO RECOMMENDATIONS"]) {
      if (monthlyCountsAccepted[monthNum] !== undefined) {
        monthlyCountsAccepted[monthNum] += 1;
      }
    } else if (study.darwineu_study_result === feasibilityKeys["FEASIBLE (ON HOLD)"]) {
      if (monthlyCountsPending[monthNum] !== undefined) {
        monthlyCountsPending[monthNum] += 1;
      }
    } else if (study.darwineu_study_result === feasibilityKeys["NOT FEASIBLE"]) {
      if (monthlyCountsNotFeasible[monthNum] !== undefined) {
        monthlyCountsNotFeasible[monthNum] += 1;
      }
    } 
  });

  callback({
    loading: false,
    options: {
      ...props.options,
      series: [{
        name: 'Accepted',
        data: Object.values(monthlyCountsAccepted),
        color: ColorPalette[ChartColorPalette.PALETTE13][2]
      }, {
        name: 'Pending',
        data: Object.values(monthlyCountsPending),
        color: ColorPalette[ChartColorPalette.PALETTE13][1]
      }, {
        name: 'Not Feasible',
        data: Object.values(monthlyCountsNotFeasible),
        color: ColorPalette[ChartColorPalette.PALETTE13][3]
      }]
    },
  });

  if (props.ref?.current.ref !== null) 
    props.ref?.current.ref?.current.chart.update({ chart: { }})
};

export const data11 = {
  ref: null,
  loading: true,
  options: {
    chart: {
      id: "chart11",
      type: "column",
      zoomType: "y",
      backgroundColor: "transparent",
      scrollablePlotArea: {
        scrollPositionY: 1,
      },
    },
    noData: { 
      useHTML: true 
    },
    lang: { 
      noData: '<div style="color: var(--darwin-yellow)">[ NO DATA ]</div>' 
    },
    title: {
      text: `Number of study requests (${new Date().getFullYear()})`,
      align: "center",
    },
    xAxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      title: {
        text: "Study",
      },
      min: 0,
      labels: {
        step: 1
      },
      crosshair: {
        enabled: true
      },
      scrollbar: {
        enabled: true,
      },
      tickLength: 0,
    },
    yAxis: {
      min: 0,
      title: {
        text: "",
      },
    },
    rangeSelector: {
      allButtonsEnabled: false,
      buttons: [],
    },
    scrollbar: {
      enabled: true,
    },
    colors: ColorPalette[ChartColorPalette.PALETTE13],
    legend: {
      shadow: false,
      reversed: true
    },
    tooltip: {
      valueSuffix: " request(s)",
      split: true,
      shared: true,
      outside: true,
      shadow: true,
      distance: 32
    },
    plotOptions: {
      series: {
        lineWidth: 1.5,
        marker: {
          radius: 2
        }
      },
      column: {
        stacking: true,
        grouping: true,
        shadow: false,
        borderWidth: 1,
        borderRadius: 0,
      },
    },
    navigator: {
      handles: {
        width: 20,
        height: 30,
      },
      maskFill: 'rgba(78, 125, 217, 0.2)',
      outlineWidth: 0,
      enabled: true,
      xAxis: {},
      yAxis: {
        type: 'logarithmic',
      },
    },
    series: []
  },
};
