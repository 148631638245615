export interface IStudyResponse {
    "@odata.context": string;
    value: IStudy[];
}

export interface IDatabase {
    "@odata.etag": string;
    _owninguser_value: string;
    statecode: number;
    crfd9_datapartnername: string;
    versionnumber: number;
    darwineu_name: string;
    darwineu_databasesid: string;
    darwineu_database: number;
    _createdby_value: string;
    _modifiedby_value: string;
    modifiedon: string;
    createdon: string;
    overriddencreatedon: string | null;
    darwineu_acronym: string;
    _owningbusinessunit_value: string;
    statuscode: number;
    _ownerid_value: string;
    _createdonbehalfby_value: string | null;
    _modifiedonbehalfby_value: string | null;
    _owningteam_value: string | null;
    utcconversiontimezonecode: number | null;
    _darwineu_datapartner_value: string;
    timezoneruleversionnumber?: number | null;
};

export interface IStudy {
    "@odata.etag": string;
    overriddencreatedon: string | null;
    darwineu_study_implfinaloutlineapprovedbyemalink: string;
    darwineu_study_implementation_finalisation_studyco: string | null;
    darwineu_study_submissiondate: string | null;
    darwineu_study_datefeasibilityrequestbyema: string;
    darwineu_study_implementation_creation_study_code: string;
    darwineu_study_result: number;
    darwineu_study_feasibilityrequestdocument: string;
    darwineu_study_implementationstartdraftingoutline: string;
    darwineu_study_executionstartdate: string;
    darwineu_study_activephase: string;
    darwineu_study_initiationstartdate: string;
    darwineu_study_summary: string;
    darwineu_study_implementation_kickoff: string;
    darwineu_study_implfinaloutlineapprovedbyema: string;
    darwineu_study_studyteamcreated: boolean;
    darwineu_study_implstartdraftingtheprotocol: string;
    darwineu_name: string;
    darwineu_study_submittedfeasibilityassdate: string;
    timezoneruleversionnumber: number;
    darwineu_study_assoffeasibilityemadoc: string;
    darwineu_study_newanalysisdescription: string | null;
    darwineu_study_reanalysissubmitteddate: string | null;
    importsequencenumber: number | null;
    statecode: number;
    modifiedon: string;
    createdon: string;
    darwineu_study_studyrequestid: string;
    darwineu_study_implkickoffmeetingwithdps: string;
    darwineu_study_submittedfeasibilityassdoc: string;
    darwineu_studyname: string;
    darwineu_study_implfinalprotocolapprovedbyemalink: string;
    darwineu_study_disseminationresultssubmitted: string;
    darwineu_study_feasibilitytargetdate: string | null;
    processid: string | null;
    darwineu_study_approvalreportemadocument: string;
    utcconversiontimezonecode: number | null;
    darwineu_study_approvaldate: string | null;
    darwineu_study_initiation_asssigneduser: string;
    darwineu_study_implstudyprotocolsubmitted: string;
    darwineu_study_initiation_studystops: string | null;
    darwineu_study_disseminationstartdraftstudyreport: string;
    darwineu_study_classification: number;
    darwineu_studyid: string;
    darwineu_study_executionresultrevisionbypi: string;
    darwineu_study_studyteamassigned: string | null;
    darwineu_study_implfinalprotocolapprovedbyemadate: string;
    darwineu_study_studynumber: string;
    darwineu_study_implementationgithubpage: string;
    darwineu_study_assoffeasibilityemadate: string;
    darwineu_studyoutlinesubmitted: string | null;
    darwineu_phase: string | null;
    darwineu_study_implementationstartdate: string;
    versionnumber: number;
    darwineu_study_initiationtargetdate: string | null;
    darwineu_dateapprovalofimplementation: string;
    darwineu_study_disseminationstartdate: string;
    statuscode: number;
    darwineu_study_dateapprovalofexecution: string;
    darwineu_study_studycompleted: boolean;
    darwineu_study_approvalreportemadate: string;
    darwineu_study_dateapprovalofinitiation: string;
    darwineu_study_implstudyoutlinesubmitted: string;
    darwineu_study_initiation_asssignedteam: string | null;
    darwineu_databases: any | null;
    darwineu_study_implementation_eupasregisternumber: string;
    darwineu_study_disseminationuploadstudyreporteupas: string;
    darwineu_study_dateapprovalofdissemination: string | null;
    darwineu_study_initiation_dps_participate: string | null;
    darwineu_study_title: string;
    darwineu_study_implementationtargetdate: string;
    darwineu_study_dateapprovaloffeasibility: string;
    darwineu_study_resultsentdate: string | null;
    darwineu_study_executiontargetdate: string | null;
    darwineu_study_disseminationtargetdate: string;
    darwineu_study_comments: string;
    darwineu_study_datefeasibilitysubmission: string | null;
    traversedpath: string | null;
    darwineu_study_implementation_creation_github: string | null;
    darwineu_study_implementation_eupas_register: string;
    darwineu_study_feasibilityrequestdate: string;
    darwineu_Study_x_Databases: IDatabase[];
    "darwineu_Study_x_Databases@odata.nextLink": string;
    darwineu_Study_x_StudyStage: IStudyStageResponse[] | null;
};

export interface IStudyStageResponse {
    "@odata.context": string;
    value: IStudyStage[];
}

export interface IStudyStage {
    "@odata.etag": string;
    createdon: string;
    darwineu_datapartner: string | null;
    darwineu_studystage_implementation_diagnosticstodp: string | null;
    _darwineu_databaseversion_value: string | null;
    _modifiedby_value: string;
    _owningteam_value: string | null;
    utcconversiontimezonecode: number | null;
    _ownerid_value: string;
    darwineu_studystage_initiationdpreplied: string | null;
    _darwineu_studystagestatusimplementation_value: string | null;
    _darwineu_studystagestatusdissemination_value: string | null;
    _darwineu_study_value: string;
    darwineu_databases: string | null;
    darwineu_studystage_phase: number;
    _createdby_value: string;
    darwineu_studystage_enddate: string | null;
    darwineu_studystage_implementationworkordersent: string | null;
    darwineu_studystage_implementationirbsubmdate: string | null;
    overriddencreatedon: string | null;
    darwineu_studystage_executionresultsreviewedbypi: boolean | null;
    importsequencenumber: number | null;
    darwineu_comments: string | null;
    _darwineu_studystagestatusexecution_value: string | null;
    _darwineu_studystagestatusinitiation_value: string | null;
    darwineu_studystage_implementation_resultstodtz: string | null;
    darwineu_studystage_executionresultsuploaded: boolean | null;
    _createdonbehalfby_value: string | null;
    darwineu_studystage_executionnumberofruns: number | null;
    darwineu_studystage_executionstudycodeshared: boolean | null;
    modifiedon: string;
    timezoneruleversionnumber: number;
    statecode: number;
    _darwineu_database_value: string;
    darwineu_studystage_implementationworkordersigned: string | null;
    versionnumber: number;
    _modifiedonbehalfby_value: string | null;
    _owninguser_value: string;
    darwineu_name: string | null;
    statuscode: number;
    _owningbusinessunit_value: string;
    _darwineu_studystagestatus_value: string | null;
    darwineu_studystage_startdate: string | null;
    darwineu_studystage_implementationirbapprovaldate: string | null;
    darwineu_studystage_initiationdpcontacted: string | null;
    darwineu_studystageid: string;
    darwineu_Database: IDatabase;
}
  
export interface IStudies {
    "@odata.context": string;
    value: IStudy[];
};

export interface IStudyDurations {
    map(arg0: (d: any) => any): unknown;
    name: string;
    classification: string;
    feasibility: number;
    initiation: number;
    implementation: number;
    execution: number;
    dissemination: number;
};

export enum DataOutputState {
    active = "active",
    inactive = "inactive",
    both = "both"
}
  
export interface IStudyState {
    active: boolean;
    inactive: boolean;
    both: boolean;
};

export interface IStudyPhase {
    all: boolean
    phase1: boolean
    phase2: boolean
    phase3: boolean
}

export enum DataOutputPhase {
    all = "all",
    phase1 = "1",
    phase2 = "2",
    phase3 = "3",
}

export interface IStudyStage {
    stage: string;
};

export interface IStudyClassification {
    name: string;
    key: number;
};

export interface ICompactStudy {
    id: string;
    name: string;
    title: string;
    summary: string;
    studyNumber: string;
    activePhase: string;
    results: string;
    dates: ICompactStudyDates;
    documents: ICompactStudyDocuments;
    approvalDates: ICompactStudyApprovalDates;
    databases: ICompactDatabaseReference[];
};

export interface ICompactStudyDates {
    initiationStart: string;
    implementationStart: string;
    executionStart: string;
    disseminationStart: string;
    disseminationResultSubmitted: string;
    feasibilityRequest: string;
    finalOutlineApproval: string;
    finalProtocolApproval: string;
    finalProtocolApprovalDate: string;
    submissionDate: string;
};

export interface ICompactStudyDocuments {
    feasibilityRequestDoc: string;
    feasibilityAssessmentDoc: string;
    approvalReportDoc: string;
    implementationGitHubPage: string;
};

export interface ICompactStudyApprovalDates {
    feasibility: string;
    initiation: string;
    implementation: string;
    execution: string;
    dissemination: string;
};

export interface ICompactDatabaseReference {
    name: string;
    acronym: string;
    dataPartnerName: string;
};

export interface ICompactDatabase {
    darwineu_name: string;
    darwineu_acronym: string;
    crfd9_datapartnername: string;
};

export interface IKeyNamePair {
    name: string;
    key: number;
}

export const StudyClassification = [
    { name: "C0: Simple counts", key: 157530999 } as IKeyNamePair,
    { name: "C1: Off-the-shelf", key: 157530000 } as IKeyNamePair,
    { name: "C2: Routine repeated", key: 157530001 } as IKeyNamePair,
    { name: "C3: Complex", key: 157530002 } as IKeyNamePair,
    { name: "C4: Very complex", key: 157530003 } as IKeyNamePair,
];

export const StudyStages = [
    { stage: "Feasibility" },
    { stage: "Initiation" },
    { stage: "Implementation" },
    { stage: "Execution" },
    { stage: "Dissemination" },
];

export const StudyState: IStudyState = {
    active: true, 
    inactive: false,
    both: false
};

export const StudyFeasibilityResult = [
    { name: "Feasible with recommendations", key: 157530000 } as IKeyNamePair,
    { name: "Feasible with no recommendations", key: 157530001 } as IKeyNamePair,
    { name: "Feasible (on hold)", key: 157530003 } as IKeyNamePair,
    { name: "Not feasible ", key: 157530002 } as IKeyNamePair,
];