import { useState, useContext, useEffect } from 'react';
import { AppContext, IAppContextProps, IFilters, toEnum } from '../../context';
import { DataOutputPhase, DataOutputState, IStudyPhase, IStudyState, StudyClassification, StudyStages } from '../../@types/study';
import { alpha, styled } from '@mui/material/styles';
import DropdownMulti from '../DropdownMulti/DropdownMulti';
import DropdownSingle from '../DropdownSingle/DropdownSingle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import UpIcon from '@mui/icons-material/ArrowDropUp';
import DownIcon from '@mui/icons-material/ArrowDropDown';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

import './PortfolioFilters.scss'

type propTypes = {
  updateFiltersCallback: (value: any) => void;
}

const PortfolioFilters = (props: propTypes) => {
  const { updateFiltersCallback } = props
  const { 
    studies,
    dataPartners,
    filterByStudyName,
    setFilterByStudyName,
    filterByStudyType,
    setFilterByStudyType,
    filterByStudyStage,
    setFilterByStudyStage,
    filterByDataPartner,
    setFilterByDataPartner,
    filters,
    setFilters,
    stateFilter,
    setStateFilter,
    phaseFilter,
    setPhaseFilter
   } = useContext(AppContext) as IAppContextProps;
  const [showFacets, setShowFacets] = useState(true);
  const darwinBlue = "#223596";
  const darwinLightblue = "#74abdb";
  const DarwinBlueSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: darwinBlue,
      '&:hover': {
        backgroundColor: alpha(darwinBlue, theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: darwinLightblue,
    },
  }));

  const toggleFacets = () => setShowFacets(!showFacets);
  const handleFiltersChange = (event: any): IFilters => {
    const { name, checked } = event.target;
    if (name === 'ongoing') {
      setFilters({
        ...filters,
        dataOutput: {
          ...filters.dataOutput,
          ongoing: checked,
        }
      });
    } else if (name === 'completed') {
      setFilters({
        ...filters,
        dataOutput: {
          ...filters.dataOutput,
          completed: checked,
        }
      });
    } 
    else if (name === 'average') {
      setFilters({
        ...filters,
        dataOutput: {
          ...filters.dataOutput,
          average: checked,
          total: !checked,
        }
      });
    } else if (name === 'active') {
      setFilters({
        ...filters,
        dataOutput: {
          ...filters.dataOutput,
          state: {
            active: true,
            inactive: false,
            both: false
          }
        }
      });
    } else if (name === 'inactive') {
      setFilters({
        ...filters,
        dataOutput: {
          ...filters.dataOutput,
          state: {
            active: false,
            inactive: true,
            both: false
          }
        }
      });
    } else if (name === 'both') {
      setFilters({
        ...filters,
        dataOutput: {
          ...filters.dataOutput,
          state: {
            active: false,
            inactive: false,
            both: true
          }
        }
      });
    } else if (name === 'total') {
      setFilters({
        ...filters,
        dataOutput: {
          ...filters.dataOutput,
          average: !checked,
          total: checked,
        }
      });
    } else {
      setFilters({
        ...filters,
        dataOutput: {
          ...filters.dataOutput,
         [name]: checked,
        }
      });
    }

    return filters;
  };

  useEffect(() => {
    updateFiltersCallback({
      filters,
      filterByStudyName,
      filterByStudyType,
      filterByStudyStage,
      filterByDataPartner,
    });
  }, [
    filters,
    filterByStudyName,
    filterByStudyType,
    filterByStudyStage,
    filterByDataPartner,
    updateFiltersCallback
  ]);

  return (
    <>
      <div className='facets' {...({open:showFacets})}>
        {true && (
          <div className={`facets-content ${showFacets ? 'show' : 'hide'}`}>
            <Grid container direction="row" spacing={2} padding={0}>
              <Grid item xs={3}>
                <div className="dataOutputFacet">
                  <div className="header">Study status</div>
                  <Grid container direction="column" spacing={0}>
                    <Grid item>
                      <FormControlLabel
                        labelPlacement="start"
                        control={<DarwinBlueSwitch />}
                        checked={filters.dataOutput.ongoing}
                        onClick={(e) => {
                          handleFiltersChange(e);
                        }}
                        label="Ongoing"
                        name="ongoing"
                      />
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        labelPlacement="start"
                        control={<DarwinBlueSwitch />}
                        checked={filters.dataOutput.completed}
                        onClick={(e) => {
                          handleFiltersChange(e);
                        }}
                        label="Completed"
                        name="completed"
                      />
                    </Grid>
                    {/* 
                    <Grid item> 
                      <FormControlLabel
                        labelPlacement="start"
                        control={<DarwinBlueSwitch />}
                        checked={filters.dataOutput.phaseSeparated}
                        onClick={(e) => {
                          handleFiltersChange(e);
                        }}
                        label="Phase seperated"
                        name="phaseSeparated"
                      />
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        labelPlacement="start"
                        control={<DarwinBlueSwitch />}
                        checked={filters.dataOutput.classificationSeparated}
                        onClick={(e) => {
                          handleFiltersChange(e);
                        }}
                        label="Classification seperated"
                        name="classificationSeparated"
                      />
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        labelPlacement="start"
                        control={<DarwinBlueSwitch />}
                        checked={filters.dataOutput.average}
                        onClick={(e) => {
                          handleFiltersChange(e);
                        }}
                        label="Average"
                        name="average"
                      />
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        labelPlacement="start"
                        control={<DarwinBlueSwitch />}
                        checked={filters.dataOutput.total}
                        onClick={(e) => {
                          handleFiltersChange(e);
                        }}
                        label="Total"
                        name="total"
                      />
                    </Grid>
                    */}
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="studyOptionFacet">
                  <div className="header">Study</div>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <DropdownSingle
                        updateOptions={(val: any) => { 
                            setFilterByStudyName(val).then(o => {
                              setFilters({
                                ...filters,
                                studyName: o.darwineu_studyname ?? ""
                              })
                            })
                          }
                        }
                        title={filterByStudyName?.darwineu_studyname ?? "Select by name"}
                        options={
                          studies?.sort((a: any, b: any) => {
                            return a.darwineu_studyname.localeCompare(b.darwineu_studyname);
                          }).map((study: any) => study) ?? []
                        }
                        prop="darwineu_studyname"
                        searchEnabled={true}
                        chosenValue={
                          filterByStudyName ?? "_"
                        }
                      />
                    </Grid>
                      <Grid item>
                      <DropdownMulti
                        updateOptions={(val: any) => { 
                            setFilterByStudyType(val)
                            handleFiltersChange({
                              target: {
                                name: new Date().getTime(),
                                checked: null,
                              },
                            });
                          }
                        }
                        title={"Select by type"}
                        options={StudyClassification}
                        prop="name"
                        chosenValues={
                          filterByStudyType ?? StudyClassification.filter(record => record.key !== 157530999).map(item => `'${item.name}'`)
                        }
                      />
                    </Grid>
                    <Grid item>
                      <DropdownSingle
                        updateOptions={(val: any) => { 
                            setFilterByStudyStage(val).then(o => {
                              setFilters({
                                ...filters,
                                studyStage: o.stage ?? ""
                              })
                            })
                          }
                        }
                        title={filterByStudyStage?.stage ?? "Select by stage"}
                        options={StudyStages}
                        prop="stage"
                        searchEnabled={false}
                        chosenValue={
                          filterByStudyStage ?? "_"
                        }
                      />
                    </Grid>
                    <Grid item>
                      <DropdownSingle
                        updateOptions={(val: any) => { 
                            setFilterByDataPartner(val).then(o => {
                              setFilters({
                                ...filters,
                                dataPartner: o.darwineu_name ?? ""
                              })
                            })
                          }
                        }
                        title={filterByDataPartner?.darwineu_name ?? "Select by datapartner"}
                        options={
                          dataPartners?.sort((a: any, b: any) => {
                            return a.darwineu_name.localeCompare(b.darwineu_name);
                          }).map((datapartner: any) => datapartner) ?? []
                        }
                        prop="darwineu_name"
                        searchEnabled={false}
                        chosenValue={
                          filterByDataPartner ?? "_"
                        }
                      />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div className="otherFacet">
                  <div className="header">Other</div>
                  <div className='otherFacetOption'>
                    <div>
                      <DropdownSingle
                        updateOptions={(val: any) => { 
                          var item = val?.state?.toString() as keyof IStudyState;
                          if (item && filters.dataOutput.state.hasOwnProperty(item)) {
                            const obj = { 
                              target: { 
                                name: item,
                                checked: true
                              } 
                            };

                            var status: DataOutputState | null = toEnum(DataOutputState, item);
                            if (status === DataOutputState.active) {
                              setStateFilter(status)
                            } else if (status === DataOutputState.inactive) {
                              setStateFilter(status)
                            } else if (status === DataOutputState.both) {
                              setStateFilter(status)
                            }
                            
                            handleFiltersChange(obj)
                          }
                        }}
                        title={stateFilter?.toLowerCase() ? `State: ${stateFilter?.charAt(0).toUpperCase() + stateFilter.substring(1, stateFilter.length)}` : "Select [ Active or Inactive ]"}
                        options={[
                          { state: DataOutputState.active }, 
                          { state: DataOutputState.inactive }, 
                          { state: DataOutputState.both }
                        ]}
                        prop="state"
                        searchEnabled={false}
                        chosenValue={(() => {
                            return { state: stateFilter }
                          }
                        )()}
                      />
                    </div>
                  </div>
                  <div className='otherFacetOption'>
                    <div>
                      <DropdownSingle
                        updateOptions={(val: any) => { 
                          var item = val?.phase?.toString() as keyof IStudyPhase
                          if (item) {
                            var phase: DataOutputPhase | null = toEnum(DataOutputPhase, item);
                            if (phase === DataOutputPhase.all) {
                              setPhaseFilter(phase);
                              setFilters({
                                ...filters,
                                dataOutput: {
                                  ...filters.dataOutput,
                                  phase: {
                                    ...filters.dataOutput.phase,
                                    all: true,
                                    phase1: false,
                                    phase2: false,
                                    phase3: false,
                                  }
                                }
                              });
                            } else if (phase === DataOutputPhase.phase1) {
                              setPhaseFilter(phase);
                              setFilters({
                                ...filters,
                                dataOutput: {
                                  ...filters.dataOutput,
                                  phase: {
                                    ...filters.dataOutput.phase,
                                    all: false,
                                    phase1: true,
                                    phase2: false,
                                    phase3: false,
                                  }
                                }
                              });
                            } else if (phase === DataOutputPhase.phase2) {
                              setPhaseFilter(phase);
                              setFilters({
                                ...filters,
                                dataOutput: {
                                  ...filters.dataOutput,
                                  phase: {
                                    ...filters.dataOutput.phase,
                                    all: false,
                                    phase1: false,
                                    phase2: true,
                                    phase3: false,                                }
                                }
                              });
                            } else if (phase === DataOutputPhase.phase3) {
                              setPhaseFilter(phase);
                              setFilters({
                                ...filters,
                                dataOutput: {
                                  ...filters.dataOutput,
                                  phase: {
                                    ...filters.dataOutput.phase,
                                    all: false,
                                    phase1: false,
                                    phase2: false,
                                    phase3: true,
                                  }
                                }
                              });
                            } 
                          }
                        }}
                        title={phaseFilter?.toLowerCase() ? `Phase: ${phaseFilter?.charAt(0).toUpperCase() + phaseFilter.substring(1, phaseFilter.length)}` : "Select Phase"}
                        options={[
                          { phase: DataOutputPhase.all }, 
                          { phase: DataOutputPhase.phase1 }, 
                          { phase: DataOutputPhase.phase2 }, 
                          { phase: DataOutputPhase.phase3 },
                        ]}
                        prop="phase"
                        searchEnabled={false}
                        chosenValue={(() => {
                            return { phase: phaseFilter }
                          }
                        )()}
                      />
                    </div>
                  </div>
                  
                  {/* <div className="timeIntervalDropdownContainer">
                    <DatePicker className="datepicker" label="Start Date" />
                    <DatePicker className="datepicker" label="End Date" />
                  </div> */}
                </div>
              </Grid>
            </Grid>
          </div>
        )}
        <Divider style={{paddingTop: false ? "15px" : "0px" }} onClick={() => toggleFacets()} className={`toggleFacets ${showFacets ? "show" : "hide"}`}>
          { showFacets ? (
            <UpIcon />
          ) : (
            <DownIcon />  
          )}
        </Divider>
      </div>
    </>
  );
};

export default PortfolioFilters;