export const isObject = (object: null) => {
  return object != null && typeof object === 'object';
}

export const deepEqual = (object1: any, object2: any) => {
  try {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (let key of keys1) {
      const val1 = object1[key];
      const val2 = object2[key];
      const areObjects = isObject(val1) && isObject(val2);

      if (
        (areObjects && !deepEqual(val1, val2)) ||
        (!areObjects && val1 !== val2)
      ) {
        return false;
      }
    }

    return true;
  } catch (error) {
    return false;
  }
};

export const extractYear = (studyRequestId: string) => parseInt(studyRequestId.substring(1, studyRequestId.indexOf('-SR')), 10);

export const uuidv4 = () => {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
    // eslint-disable-next-line 
    (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
  );
};
