import moment from "moment";
import { IStudyDurations, IStudy } from "../../../@types/study";
import { ChartColorPalette, ColorPalette } from "../../../context";

export const processData = (props: any, data: any, callback: Function) => {
  const durations: IStudyDurations[] = data.map((study: IStudy) => {
    const feasibilityDuration = moment(study.darwineu_study_initiationstartdate).diff(moment(study.darwineu_study_feasibilityrequestdate), "days") || 0;
    const initiationDuration = moment(study.darwineu_study_implementationstartdate).diff(moment(study.darwineu_study_initiationstartdate), "days") || 0;
    const implementationDuration = moment(study.darwineu_study_executionstartdate).diff(moment(study.darwineu_study_implementationstartdate), "days") || 0;
    const executionDuration = moment(study.darwineu_study_disseminationstartdate).diff(moment(study.darwineu_study_executionstartdate), "days") || 0;
    const disseminationDuration = moment(study.darwineu_study_disseminationresultssubmitted).diff(moment(study.darwineu_study_disseminationstartdate), "days") || 0;

    return {
      name: study.darwineu_study_studynumber,
      feasibility: feasibilityDuration,
      initiation: initiationDuration,
      implementation: implementationDuration,
      execution: executionDuration,
      dissemination: disseminationDuration,
    };
  })
  .filter((d: IStudyDurations) => 
    (d.feasibility >= 0 &&
    d.initiation >= 0 &&
    d.implementation >= 0 &&
    d.execution >= 0 &&
    d.dissemination >= 0) || false
  );

  const chartType = data.length === 1 ? "pie" : "column";
  let series = [];
  if (chartType === "pie") {
    const pieSeriesData = [
      { name: "Feasibility", y: durations[0]?.feasibility ?? 0, color: ColorPalette[ChartColorPalette.PALETTE13][4] },
      { name: "Initiation", y: durations[0]?.initiation ?? 0, color: ColorPalette[ChartColorPalette.PALETTE13][3] },
      { name: "Implementation", y: durations[0]?.implementation ?? 0, color: ColorPalette[ChartColorPalette.PALETTE13][2] },
      { name: "Execution", y: durations[0]?.execution ?? 0, color: ColorPalette[ChartColorPalette.PALETTE13][1] },
      { name: "Dissemination", y: durations[0]?.dissemination ?? 0, color: ColorPalette[ChartColorPalette.PALETTE13][0] },
    ]

    series.push({ name: "Durations", data: pieSeriesData });
  } else {
    series = [
      { name: "Dissemination", data: durations.map(d => d.dissemination), color: ColorPalette[ChartColorPalette.PALETTE13][0] },
      { name: "Execution", data: durations.map(d => d.execution), color: ColorPalette[ChartColorPalette.PALETTE13][1] },
      { name: "Implementation", data: durations.map(d => d.implementation), color: ColorPalette[ChartColorPalette.PALETTE13][2] },
      { name: "Initiation", data: durations.map(d => d.initiation), color: ColorPalette[ChartColorPalette.PALETTE13][3] },
      { name: "Feasibility", data: durations.map(d => d.feasibility), color: ColorPalette[ChartColorPalette.PALETTE13][4] },
    ];
  }

  callback({
    loading: false,
    options: {
      ...props.options,
      chart: {
        ...props.options.chart,
        type: chartType,
        scrollablePlotArea: {
          ...props.options.chart.scrollablePlotArea,
          minWidth: 85 * data.length
        },
      },
      ...(chartType === "column" && {
        xAxis: {
          ...props.options.xAxis,
          categories: durations.map(d => d.name),
        }
      }),
      series: series,
    },
  });

  if (props.ref?.current?.ref !== null) 
    props.ref?.current?.ref?.current.chart.update({ chart: { }})
};

export const data09 = {
  ref: null,
  loading: true,
  options: {
    chart: {
      id: "chart09",
      type: "column",
      zoomType: "y",
      backgroundColor: "transparent",
      scrollablePlotArea: {
        scrollPositionY: 1,
      },
    },
    noData: { 
      useHTML: true 
    },
    lang: { 
      noData: '<div style="color: var(--darwin-yellow)">[ NO DATA ]</div>' 
    },
    title: {
      text: "Overall duration of studies by study stage (days)",
      align: "center",
    },
    xAxis: {
      categories: ["Study"],
      title: {
        text: "Study",
      },
      min: 0,
      labels: {
        step: 1
      },
      crosshair: {
        enabled: true
      },
      scrollbar: {
        enabled: true,
      },
      tickLength: 0,
    },
    yAxis: {
      min: 0,
      max: 365,
      title: {
        text: "",
      },
    },
    rangeSelector: {
      allButtonsEnabled: false,
      buttons: [],
    },
    scrollbar: {
      enabled: true,
    },
    colors: ColorPalette[ChartColorPalette.PALETTE13],
    legend: {
      shadow: false,
      reversed: true
    },
    tooltip: {
      valueSuffix: " day(s)",
      split: true,
      shared: true,
      outside: false,
      shadow: true,
      distance: 32
    },
    plotOptions: {
      series: {
        lineWidth: 1.5,
        marker: {
          radius: 2
        }
      },
      column: {
        stacking: true,
        grouping: true,
        shadow: false,
        borderWidth: 1,
        borderRadius: 0,
      },
    },
    navigator: {
      handles: {
        // lineWidth: 1,
        width: 20,
        height: 30,
      },
      maskFill: 'rgba(78, 125, 217, 0.2)',
      outlineWidth: 0,
      enabled: true,
      xAxis: {},
      yAxis: {
        type: 'logarithmic',
      },
    },
    series: [
      {
        name: "Dissemination",
        data: [],
      },
      {
        name: "Execution",
        data: [],
      },
      {
        name: "Implementation",
        data: [],
      },
      {
        name: "Initiation",
        data: [],
      },
      {
        name: "Feasibility",
        data: [],
      },
    ],
  },
};
