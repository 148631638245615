import HomeIcon from "@mui/icons-material/Home";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import LogoutIcon from "@mui/icons-material/Logout";
import DatasetIcon from "@mui/icons-material/Dataset";
import SchoolIcon from "@mui/icons-material/School";
import { ListItemButton, ListItemIcon } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar/AppBar";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import { CSSObject, Theme, styled } from "@mui/material/styles";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { AppContext, IAppContextProps } from "../../context";
import { DrawerHeader } from "../DrawerHeader/DrawerHeader";
import LoadingOverlay from "../LoadingOverlay/LoadingOverlay";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { defaultConfig, graphConfig } from "../../auth";
import axios from "axios";

import DarwinLogo from "../../img/darwin-eu-logo.png";

const LoggedIn = () => {
  const [loading, setLoading] = useState(true);
  const [graphData, setGraphData] = React.useState<any>(null);

  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  
  const getProfile = async () => {
    try {
      setLoading(true);
      const request = await instance.acquireTokenSilent({
        ...defaultConfig,
        account: accounts[0],
      });

      instance.acquireTokenSilent(request).then((response) => {
        axios
          .get(graphConfig.graphMeEndpoint, {
            headers: {
              Authorization: `Bearer ${response.accessToken}`,
            },
          })
          .then((response) => {
            setGraphData(response.data);
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            setLoading(false);
          })
      });
    } catch (e) {
      console.error(e);
    }
  };

  React.useEffect(() => {
    if (isAuthenticated) {
      getProfile();
    }
    // eslint-disable-next-line
  }, [isAuthenticated, instance, accounts]);

  return (
    <div className="profile">
      {loading ? (<LoadingOverlay isLoading={loading} hideText={true} />): (
        graphData ? (
          <div className="welcome">
            <span>Hi {graphData?.givenName || "anonymous :)"}!</span>
          </div>
        ) : null
      )}
    </div>
  );
};

const drawerWidth = 280;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

interface IAppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<IAppBarProps>(({ theme, open }) => ({
  background: "white",
  color: "#004494",
  width: `calc(100% - calc(${theme.spacing(7)} + 1px))`,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    background: "white",
    color: "#004494",
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function SideBar() {
  const { title, setTitle } = useContext(
    AppContext,
  ) as IAppContextProps;
  const [open, setOpen] = useState(true);
  const [tiles, setTiles] = useState<
    { title: string; link: string; icon: ReactElement }[]
  >([]);

  useEffect(() => {
    const _tiles: { title: string; link: string; icon: ReactElement }[] = [];
    _tiles.push({
      title: "Home",
      link: "",
      icon: <HomeIcon />,
    });
    _tiles.push({
      title: "Study",
      link: "study",
      icon: <SchoolIcon />,
    });
    _tiles.push({
      title: "Portfolio",
      link: "portfolio",
      icon: <DatasetIcon />,
    });
    _tiles.push({
      title: "FAQ",
      link: "faq",
      icon: <LiveHelpIcon />,
    });
    _tiles.push({ title: "Sign out", link: "logout", icon: <LogoutIcon /> });
    setTiles(_tiles);
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex", background: "white", width: "100%" }}>
      <AppBar position="fixed" open={open}>
        <Toolbar style={{ display: "flex", verticalAlign: "middle" }}>
          <div
            style={{
              justifyContent: "flex-start",
              verticalAlign: "middle",
              width: "100%",
            }}
          >
            <h3>{title}</h3>
          </div>
          <a href={"https://darwin-eu.org/"} target={"_blank"} rel="noreferrer">
            <img
              style={{ maxHeight: "44px" }}
              className={"right"}
              src={DarwinLogo}
              alt={"DARWIN EU®"}
            />
          </a>
        </Toolbar>
      </AppBar>
      <Drawer
        className="sidebar"
        variant="permanent"
        open={open}
        PaperProps={{ sx: { background: "transparant", color: "#FFFFFF" } }}
      >
        <DrawerHeader className="header">
          {open && (
            <ListItem
              key={"Dashboard"}
              disablePadding
              sx={{ display: "block", textAlign: "center", fontWeight: 900 }}
            >
              <img src={require("../../img/logo.png")} alt={"Dashboard"} className={"study-dashboard-logo"} /><br /> 
            </ListItem>
          )}
          {false && (
            <>
              {open ? (
                <IconButton onClick={handleDrawerClose}>
                  <KeyboardDoubleArrowLeftIcon sx={{ color: "white" }} />
                </IconButton>
              ) : (
                <IconButton onClick={handleDrawerOpen}>
                  <KeyboardDoubleArrowRightIcon sx={{ color: "white" }} />
                </IconButton>
              )}
            </>
          )}
        </DrawerHeader>
        <List>
          {tiles.map((text) => (
            <ListItem
              component={Link}
              to={text.link}
              key={text.link}
              disablePadding
              className="sidebar-menu-item"
              sx={{
                display: "block",
                textDecoration: "none",
                color: "#000",
              }}
              onClick={() => {
                if (setTitle) 
                  setTitle(<div>{text.title}</div>)
                }
              }
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color: "var(--darwin-blue)",
                  }}
                  className="icon"
                >
                  {text.icon}
                </ListItemIcon>
                <ListItemText
                  primary={text.title}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Box
          sx={{
            display: "grid",
            width: "100%",
            color: "#00000066",
            height: "100%",
            justifyItems: "center",
            alignItems: "end",
            verticalAlign: "bottom",
            paddingBottom: "20px"
          }}
        >
          <div style={{textAlign: "center", color: "rgb(0 0 0 / 20%)"}}>
            <LoggedIn />
          </div>
        </Box>
      </Drawer>
    </Box>
  );
}
