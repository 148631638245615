import React, { Component } from "react";

import "./LoadingCube.scss";

class App extends Component {
  render() {
    return (
      <div
        className={
          "loader loading-cube " + (this.props.isLoading ? "show-loading" : "hide-loading")
        }
      >
        <div className="container">
          <div className="cube">
            <div className="sides">
              <div className="top"></div>
              <div className="right"></div>
              <div className="bottom"></div>
              <div className="left"></div>
              <div className="front"></div>
              <div className="back"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
