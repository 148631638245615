import { SyntheticEvent, useContext, useState } from "react";
import { TabPanel, a11yProps } from "../../components/TabPanel/TabPanel";
import StudySelector from "../../components/StudySelector/StudySelector";
import { AppContext } from "../../context";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import flag1 from "./../../img/flag_1.png";
import flag2 from "./../../img/flag_2.png";
import flag3 from "./../../img/flag_3.png";

import "./Study.scss";

export const Study = () => {
  const { selectedStudy, setSelectedStudy } = useContext<any>(AppContext);
  const { studies } = useContext<any>(AppContext);

  const study = (selectedStudy)

  const formatDate = function(dateString: string | number | Date) {
    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
      return '-';
    }

    const day = date.getUTCDate();
    const month = date.getUTCMonth();
    const year = date.getUTCFullYear();

    function getOrdinalSuffix(day: number) {
        if (day > 3 && day < 21) return 'th';
        switch (day % 10) {
            case 1: return 'st';
            case 2: return 'nd';
            case 3: return 'rd';
            default: return 'th';
        }
    }

    const monthNames = [
        "January", 
        "February",
        "March", 
        "April", 
        "May", 
        "June",
        "July", 
        "August", 
        "September", 
        "October", 
        "November", 
        "December"
    ];

    const formattedDate = `${day}${getOrdinalSuffix(day)} ${monthNames[month]} ${year}`;

    return formattedDate;
  }

  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrevious = () => {
    setCurrentIndex((prevIndex: any) => prevIndex > 0 ? prevIndex - 1 : studies.length - 1);
    setSelectedStudy(studies[currentIndex]);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex: any) => prevIndex < studies.length - 1 ? prevIndex + 1 : 0);
    setSelectedStudy(studies[currentIndex]);
  };

  const [value, setValue] = useState(0);
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className="page study">
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs">
          <Tab label="Overview" {...a11yProps(0)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <div className="filters">
          <span className="label">Filters</span>
          <StudySelector />
        </div>
        <div className="tab-content study-details">
          <br />
          <div className="studyname"><div>{study?.darwineu_studyname ?? ""}</div></div>
          <div><div className="studyitemrow">Study Number:</div>{study?.darwineu_study_studynumber ?? ""}</div>
          <div><div className="studyitemrow">Study Request ID:</div>{study?.darwineu_study_studyrequestid ?? ""}</div>
          <div><div className="studyitemrow">EU PAS Register Number:</div>{study?.darwineu_study_implementation_eupasregisternumber ?? ""}</div>
          <div><div className="studyitemrow">Final Protocol approved by EMA: </div>
            <br />
            <a target="_blank" rel="noreferrer" href={study?.darwineu_study_implfinalprotocolapprovedbyemalink ?? ""}>{study?.darwineu_study_implfinalprotocolapprovedbyemalink ?? ""}</a>
            <br /><br />
          </div>
          <div className="row">
            <div className="column">
              <span className="label">Comments:</span>
              <div style={{ textAlign: "left", marginTop: "10px", marginRight: "25px", }} dangerouslySetInnerHTML={{ __html: study?.darwineu_study_comments ?? "-" }}></div>
            </div>
          </div>
          <div className="row">
            <div className="column">
              <span className="label">Summary:</span>
              <div style={{ textAlign: "left", marginTop: "10px", marginRight: "25px", }} dangerouslySetInnerHTML={{ __html: study?.darwineu_study_summary ?? "-" }}></div>
            </div>
            <div className="column">
              <span className="label">Datasources:</span>
              {study?.darwineu_Study_x_Databases ? (
                <ul className="datasources" style={{ marginTop: "10px", }}>
                  {study?.darwineu_Study_x_Databases.map((study: any, index: any) => (
                    <li key={index}>{study?.darwineu_name}</li>
                  ))}
                </ul>
              ) : "-"}
            </div>
          </div>
        </div>
        <div className="row timeline">
          <div className="column">
            <img
              style={{ maxHeight: "40px" }}
              className={"right"}
              src={flag1}
              alt="flag"
            />
            <div className="text" style={{ fontWeight: "bold" }}>Start date</div>
            <div className="text">{formatDate(study?.createdon) ?? ""}</div>
          </div>
          <div className="column">
            <img
              style={{ maxHeight: "40px" }}
              className={"right"}
              src={flag3}
              alt="flag"
            />
            <div className="text" style={{ fontWeight: "bold" }}>Feasibility Request by EMA</div>
            <div className="text">{formatDate(study?.darwineu_study_datefeasibilityrequestbyema) ?? ""}</div>
          </div>
          <div className="column">
            <img
              style={{ maxHeight: "40px" }}
              className={"right"}
              src={flag1}
              alt="flag"
            />
            <div className="text" style={{ fontWeight: "bold" }}>Initiation Start Date</div>
            <div className="text">{formatDate(study?.darwineu_study_initiationstartdate) ?? ""}</div>
          </div>
          <div className="column">
            <img
              style={{ maxHeight: "40px" }}
              className={"right"}
              src={flag3}
              alt="flag"
            />
            <div className="text" style={{ fontWeight: "bold" }}>Approval Of Initiation</div>
            <div className="text">{formatDate(study?.darwineu_study_dateapprovalofinitiation) ?? ""}</div>
          </div>
          <div className="column">
            <img
              style={{ maxHeight: "40px" }}
              className={"right"}
              src={flag1}
              alt="flag"
            />
            <div className="text" style={{ fontWeight: "bold" }}>Implementation Start date</div>
            <div className="text">{formatDate(study?.darwineu_study_implementationstartdate) ?? ""}</div>
          </div>
          <div className="column">
            <img
              style={{ maxHeight: "40px" }}
              className={"right"}
              src={flag1}
              alt="flag"
            />
            <div className="text" style={{ fontWeight: "bold" }}>Start Drafting The Protocol</div>
            <div className="text">{formatDate(study?.darwineu_study_implstartdraftingtheprotocol) ?? ""}</div>
          </div>
          <div className="column">
            <img
              style={{ maxHeight: "40px" }}
              className={"right"}
              src={flag3}
              alt="flag"
            />
            <div className="text" style={{ fontWeight: "bold" }}>Final Protocol Approved By EMA</div>
            <div className="text">{formatDate(study?.darwineu_study_implfinalprotocolapprovedbyemadate) ?? ""}</div>
          </div>
          <div className="column">
            <img
              style={{ maxHeight: "40px" }}
              className={"right"}
              src={flag1}
              alt="flag"
            />
            <div className="text" style={{ fontWeight: "bold" }}>Dissemination Start date</div>
            <div className="text">{formatDate(study?.darwineu_study_disseminationstartdate) ?? ""}</div>
          </div>
          <div className="column">
            <img
              style={{ maxHeight: "40px" }}
              className={"right"}
              src={flag2}
              alt="flag"
            />
            <div className="text" style={{ fontWeight: "bold" }}>Approval of Study Report by EMA</div>
            <div className="text">{formatDate(study?.darwineu_study_approvalreportemadate) ?? ""}</div>
          </div>
        </div>
        <div className="row buttons">
          <div className="column">
            <div className="buttons">
              <button onClick={handlePrevious} className="previous-button">Previous</button>
              <button onClick={handleNext} className="next-button">Next</button>
            </div>
          </div>
        </div>
      </TabPanel>

    </div>
  );
};
