import React, { Component, RefObject } from "react"
import * as Highcharts from 'highcharts'
import Highstock from "highcharts/highstock"
import highGantt from "highcharts/highcharts-gantt"

import HighchartsReact from 'highcharts-react-official'
import LoadingCube from "../LoadingCube/LoadingCube"

import highchartsAccessibility from "highcharts/modules/accessibility";

import HC_more from "highcharts/highcharts-more"

import "./CustomHighcharts.scss"

require('highcharts/modules/accessibility')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);
require('highcharts/modules/full-screen')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/data')(Highcharts);

HC_more(Highcharts)

class CustomHighcharts extends Component<any, any> {
  public ref: RefObject<any>;

  constructor(props: any) {
    super(props)

    this.ref = React.createRef();
    this.toggleFullscreen = this.toggleFullscreen.bind(this)
    this.fetchData = this.fetchData.bind(this)
    
    this.state = {
      isFullscreen: props.isFullscreen,
      isLoading: props.isLoading,
      id: props.id,
      title: props.title,
      options: {
        ...props.config.options,
        chart: {
          ...props.config.options?.chart,
          fontFamily: 'var(--font-family)',
          type: props.type,
        },
      },
      series: props.config.options.series ?? [],
    }
  }

  private toggleFullscreen() {
    this.setState((state: any, props: any) => ({
      ...state,
      isFullscreen: !this.state.isFullscreen
    }))
  }

  private fetchData = async () => {
    this.props.fetchData(this.props.chartType)
    this.setState((prevState: any) => ({
      ...prevState,
        isLoading: true
    }))
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps !== this.props) {
      this.setState((prevState: any) => ({
        ...prevState,
        isLoading: this.props.isLoading,
        series: this.props.config.options.series
      }))
    }
  }

  componentDidMount() {
    
  }

  render() {
    const { isLoading } = this.state

    const getChartType = (constructorType: string) => {
      switch(constructorType) {
        case "stockChart":
          highchartsAccessibility(Highstock);
          return Highstock
        case "ganttChart":
          highchartsAccessibility(highGantt); 
          return highGantt
        default:
          highchartsAccessibility(Highcharts);
          return Highcharts
      }
    }

    return (
      <>
        <div className={`chart-container-highcharts chart ${this.props.id}`}>
          <div className={"label-wrapper"+ (this.state.hasToolbar ? "-no-toolbar" : "")}>
            <span className="label">{this.state.title}</span>
          </div>
          <LoadingCube isLoading={isLoading} />
          <HighchartsReact
            ref={this.ref}
            constructorType={this.props.constructorType}
            highcharts={getChartType(this.props.constructorType)}
            width={this.props.width ?? "100%"}
            height={this.props.height ?? "auto"}
            className="custom-chart"
            options={this.props.config.options}
            series={this.props.config.series}
            type={this.props.type}
            id={this.props.id}
          />
        </div>
      </>
    )
  }
}

export default CustomHighcharts
