import Box from "@mui/material/Box";
import "./Home.scss";

export const Home = () => {

  return (
    <div className="page home">
      
      <Box
        component="main"
        height="100%" display="flex" flexDirection="column"
        sx={{ flexGrow: 1, p: 4, background: "white", color: "black" }}
      >

        <div className="content">
          <h1>Welcome to the Study Portfolio Dashboard</h1>
          <h2>Your Comprehensive Overview of DARWIN EU® Studies</h2>
          <p>
            Welcome to the Study Portfolio Dashboard, an innovative tool developed by DARWIN EU® CC.<br />Our web application is designed to provide you with a seamless and detailed overview of the progress and outcomes of all studies within DARWIN EU®.
          </p>
          <br />
          <p>Monitor and Analyze with Ease</p>
          <p>
            With the Study Portfolio Dashboard, you can:
          </p>
          <br />
          <ul>
            <li><strong>Get a Helicopter View:</strong><br />Instantly access a high-level view of all ongoing and completed studies within DARWIN EU®. Our intuitive interface allows you to stay updated on the status of each study at any point in time.</li><br />
            <li><strong>Track Progress:</strong><br />Whether a study is just beginning, midway through, or wrapping up, you can track its progress and ensure it stays on course.</li><br />
            <li><strong>View Comprehensive Statistics:</strong><br />Dive into detailed statistics for the entire DARWIN EU® output. Analyze data trends, study results, and more with our powerful analytics tools.</li><br />
          </ul>
        </div>
      </Box>
    </div>
  );
};
