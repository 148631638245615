import { useState } from "react";

type SetAsyncValue<T> = (newValue: T) => Promise<any>;

const useAsyncSetter = <T,>(initialValue: T): [T, SetAsyncValue<T>] => {
  const [value, setValue] = useState<T>(initialValue);
  const setAsyncValue: SetAsyncValue<T> = (newValue) => {
    return new Promise<T>((resolve) => {
      setValue(newValue);
      resolve(newValue);
    });
  };

  return [value, setAsyncValue];
}

export default useAsyncSetter;
