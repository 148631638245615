import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app';
import { msalConfig } from './auth';
import { PublicClientApplication } from '@azure/msal-browser';
import AppContextProvider from "./context";
import { MsalProvider } from '@azure/msal-react';
import "./index.scss"

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const msalInstance = new PublicClientApplication(msalConfig);

root.render(
  <React.StrictMode>
    <AppContextProvider>
      <MsalProvider instance={msalInstance}>
          <App />
      </MsalProvider>
    </AppContextProvider>
  </React.StrictMode>
);
